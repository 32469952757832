@charset "UTF-8";

a {
  color: #19113c;
}
a:hover {
  color: #9068be;
}
.btn-primary:focus,
.btn-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background-color: #19113c !important;
  border-color: #19113c !important;
}

button.btn.btn-primary:disabled {
  background-color: #b1b1b1;
  border-color: #b1b1b1;
  pointer-events: none;
}

.suggestion {
  cursor: pointer;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.suggestion:hover {
  font-weight: bold;
}

iframe#launcher-frame {
  min-height: inherit;
}

/* HEADER */

.header {
  z-index: 9999;
}
.nav-header {
  width: 135px !important;
}

/* LOGIN */

#login-page1 body {
  background: url(assets/images/v2/Slide1.JPG) center no-repeat;
  background-attachment: fixed;
}
#login-page1 body::after {
  background: transparent;
}
.login-bg header {
  margin: 15px 0 50px;
}
.login-bg .logo {
  width: auto;
  margin: auto;
}
.login-bg .logo a {
  display: block;
}
.login-bg .logo a img {
  max-width: 190px;
  object-fit: cover;
  width: 100%;
}
.login-bg .btn-back {
  position: absolute;
  right: 15px;
  top: 10px;
}
.login-bg .btn-back a {
  display: block;
  font-size: 11px;
  color: #19113c;
}
.login-bg .btn-back a:hover {
  color: #9068be;
}
.login-bg .btn-back a img {
  width: 4px;
  height: auto;
  margin-right: 5px;
}
.login-bg .form-input-content .card {
  margin: 25px 10px;
}

.login-discord .div {
  width: 100%;
  display: block;
  position: relative;
  margin: 25px 0;
}
.login-discord .div::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
  position: absolute;
  left: 0;
  top: 7px;
  z-index: 0;
}
.login-discord .div small {
  background-color: #fff;
  text-transform: uppercase;
  color: #cbcbcb;
  position: relative;
  z-index: 1;
  padding: 0 20px;
}
.login-discord .btn {
  max-width: 100% !important;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.login-discord .btn img {
  width: 100%;
  max-width: 24px;
  height: auto;
  margin: 0 15px 0 0;
}
.login-discord .btn i {
  font-size: 24px;
  margin-right: 10px;
}
.login-discord .btn p {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* CHAMADAS */

.thumb-event figure {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px 0 20px;
}
.thumb-event figure img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

/* SIGN UP */

.thumb-user.left figure {
  margin-left: initial;
  margin-right: auto;
}
.thumb-user figure {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px auto 20px;
}
.thumb-user figure img {
  width: 100%;
  object-fit: cover;
}

/* HEADER */

.dropdown-profile li a span {
  font-size: 12px;
}

/* BREADCRUMB */

.page-titles {
  z-index: 0;
}

/* TITLE */

.content-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.content-title h1,
.frame-inscriptions h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #454545 !;
}
.content-title .idiomas span {
  font-size: 12px;
}
.content-title .idiomas a {
  display: inline-block;
  margin-left: 5px;
}
.content-title .idiomas a img {
  width: 18px;
  object-fit: cover;
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 0.3s;
}
.content-title .idiomas a:hover img {
  transform: scale(0.8);
}
.content-title .idiomas a.active img {
  filter: none;
  opacity: 1;
}

/* DASHBOARD */

.fix-sidebar.mini-nav .brand-logo a,
.brand-logo a {
  width: 130px;
  float: left;
  margin: 5px 0 5px 0px;
}
.brand-logo a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* INSCRIPTION */

.frame-inscriptions {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 25px;
}
label.input-label {
  font-weight: 700;
  margin-bottom: 0 !important;
  font-size: 12px !important;
}
span.input-required {
  color: #e50071;
}
.table input[type="checkbox"] {
  margin: 0;
  min-height: 18px;
}
input[type="checkbox"],
input[type="radio"] {
  height: 18px;
  width: 18px;
  margin-top: 2px;
}

.optionsLabel {
 margin-left: 4px; 
}

.inscriptions_picture {
  max-height: 300px;
}

.resume .accordion .accordion-item .accordion-header {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.resume .accordion .accordion-item:last-of-type .accordion-header{
  border: 0;
}
.resume .accordion .accordion-item .accordion-header .accordion-button:focus, 
.resume .accordion .accordion-item .accordion-header .accordion-button:active {
  filter: none;
  border: 0;
  outline: 0;
}
.resume .accordion .accordion-item .accordion-header .accordion-button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098 !important;
  font-weight: 700;
  border: 0;
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
}
.resume .accordion .accordion-item .accordion-header.frame-nok .accordion-button {
  color: #e50071 !important;
}

.resume .accordion .accordion-item .accordion-header .accordion-button:before{
  content: "";
  border-color: initial;
  border-style: solid;
  border-width: 2px 0 0 2px;
  width: 7px;
  height: 8px;
  position: absolute;
  right: 4px;
  top: 46%;
  transform: rotate(-135deg) translateY(-50%);
  -webkit-transform: rotate(-135deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  transition: all .3s ease-out;
}
.resume .accordion .accordion-item .accordion-header .accordion-button button.btn.btn-link {
  color: #9068be;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
  cursor: pointer;
}
.resume .accordion .accordion-item .accordion-header .accordion-button button.btn.btn-link:hover{
  color: #7649aa;
}

.frame-inscriptions .form-group h3{
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #454545;
  margin-top: 20px;
}

.frame-inscriptions .highlight {
  color: #e50071 !important;
}

/* FORM */

form .div {
  margin: 30px 0 25px;
}
form .div.internal {
  margin: 10px 0 15px;
}
.form-group {
  margin-bottom: 12px;
}
.form-group label {
  margin-bottom: 5px;
}
.form-custom select, .form-custom .dropdown {
  min-height: 36px;
}
.form-custom .input-group {
  margin-bottom: 20px;
}
.form-custom .input-group label {
  width: 100%;
}
.form-custom .checkbox {
  margin-left: 15px;
  font-size: 12px;
}
.form-custom .checkbox.margin-left-0 {
  margin-left: 0;
}
.form-custom .checkbox > div {
  padding: 0;
}
.form-custom .checkbox input:first-child {
  margin-left: 0;
}
.form-custom .checkbox input {
  margin: 0 10px 0 20px;
}
.content-btn {
  margin: 25px 0 0;
}
.form-custom .alet {
  margin-top: 25px;
}
.content-btn button {
  margin-bottom: 0;
}
.content-btn .btn {
  margin-right: 10px;
}
.content-btn .btn:last-child {
  margin-right: 0;
}
.form-custom button,
.form-custom input[type="submit"] {
  margin-bottom: 20px;
}
.form-custom .alert {
  text-align: center;
}
.form-custom .custom-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-custom .custom-checkbox input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  background-image: url(assets/images/v2/icon-gostei-empty.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  cursor: pointer;
  border: 0;
  width: 24px;
  margin-top: 0;
  margin-right: 5px;
}
.form-custom .custom-checkbox input[type="checkbox"]:checked {
  background-image: url(assets/images/v2/icon-gostei-full.svg);
}
.form-custom .content-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.form-custom .content-radio .radio {
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-custom .content-radio .radio:last-child {
  margin-right: 0;
}
.form-custom .content-radio .radio input[type="checkbox"],
.form-custom .content-radio .radio input[type="radio"] {
  margin-top: 0;
  margin-right: 5px;
}
.form-custom textarea {
  min-height: 150px;
  height: 150px;
  resize: none;
}
.form-custom textarea.feedback-categoria {
  min-height: 70px;
  height: 70px;
}
.form-custom .multiple {
  min-height: 150px;
  height: 150px;
  resize: none;
}
.form-custom .form-group.buttons,
.form-custom .form-group .buttons {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}
.form-custom .form-group.buttons button,
.form-custom .form-group .buttons button {
  margin: 29px 0 0 10px;
}
.form-custom .input-group > .custom-file,
.form-custom .custom-file .custom-file-label,
.form-custom .custom-file .custom-file-input {
  height: 45px;
}
.form-custom .custom-file .custom-file-label {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 11px;
}
.custom-file-label::after {
  content: "Selecione";
  height: 43px;
  font-size: 12px;
  padding-top: 13px;
}
.form-custom input[type="file"] {
  padding: 0;
}
.form-control-sm {
  height: 36px;
}
textarea.form-control-sm {
  height: 120px;
  resize: none;
}

/* SELECTED FUNCTIONS */

.selected-functions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.selected-functions .functions {
  background-image: url(assets/images/v2/delete.svg);
  background-size: 7px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  background-color: #9068be;
  padding: 5px 25px 5px 10px;
  border-radius: 30px;
  color: #fff;
  font-size: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.selected-functions .functions:last-child {
  margin-right: 0;
}
.selected-functions .functions:hover {
  background-color: #7649aa;
}

/* FORM JOGOS */

.bg-jogos {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 20px;
}
.bg-jogos .row:last-child {
  margin-bottom: 0 !important;
}

/* BTN ADD FORM */

.btn-form {
  width: auto;
  display: block;
  text-align: right;
}
.btn-form img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  transition: all 0.2s;
  margin-bottom: 4px;
}
.btn-form:hover img {
  transform: rotate(90deg);
}

/* SLIDER FORM */

.slider {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  max-width: 200px;
  height: 5px;
  background-color: #eee;
  border-radius: 10px;
  margin: 5px 0 10px;
  display: block;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #678198;
  width: 12px;
  height: 12px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #678198;
  width: 12px;
  height: 12px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}
.container-slider .rating {
  display: flex;
  flex-direction: row-reverse;
}
.container-slider .rating .slider-range-value {
  display: inline-block;
}
.container-slider .btn-outline-warning {
  float: left;
  color: #ffc107;
  border-color: #ffc107;
  padding: 1px 8px 0 0;
  font-size: 16px;
}
.container-slider .btn-outline-warning span {
  color: #ffc107;
}

/* 123 FORM */

.form123 {
  margin-top: 15px;
}
.form123 a {
  background-color: #9068be;
  border-color: #9068be;
  color: #fff;
  padding: 7px 18px;
  border-radius: 0.25rem;
}
.form123 a:hover {
  background-color: #7649aa;
  color: #fff;
  border-color: #7649aa;
}
.form123 a:focus,
.form123 a:active {
  background-color: #19113c;
  border-color: #19113c;
}

/* FILTER */

.form-custom .filter h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.form-custom .filter .form-group label {
  margin-bottom: 0;
  font-size: 12px;
}
.form-custom .filter .form-group select,
.form-custom .filter .form-group .dropdown,
.form-custom .filter .form-group input[type="date"] {
  width: 100%;
  /* min-width: 150px; */
  color: #6f777f;
}
.form-custom .filter .form-group:first-of-type {
  display: initial !important;
}
.form-custom .filter .form-group:last-of-type {
  display: flex;
  flex-direction: column;
}

.select, .btn.select {
  appearance: none;
  -webkit-appearance: none;
  cursor: context-menu !important;
  background-image: url(https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-arrow-down-b-1024.png);
  background-position-x: calc(100% - 12px);
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 10px;
}

/* TAB */

.nav-tabs {
  width: 100%;
}

.nav-tabs .nav-item button {
  margin: 0 !important;
}

.tab-content {
  width: 100%;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 20px 15px;
}

/* EVENT LIST */

.event-list {
  width: 100%;
  padding-top: 10px;
}
.event-list .row {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  align-items: center;
}
.event-list.games.avaliacao > .row {
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.event-list.games.avaliacao > .row:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.event-list.games.avaliacao .row a {
  padding: 0;
}
.event-list.games.avaliacao .row a:hover {
  background-color: transparent;
  color: #9068be !important;
}
.event-list.games.avaliacao .row a p,
.event-list.games.avaliacao .row a h2,
.event-list.games.avaliacao .row a h3 {
  transition: all 0.2s;
}
.event-list.games.avaliacao .row a:hover p,
.event-list.games.avaliacao .row a:hover h2,
.event-list.games.avaliacao .row a:hover h3 {
  color: #9068be !important;
}
.event-list.games.avaliacao .row form.form-custom {
  margin-top: 10px;
}
.event-list .row a {
  display: block;
  padding: 5px 10px;
  border-radius: 10px;
}
.event-list.games .row .row {
  align-items: flex-start;
}
.event-list .row:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 0;
}
.event-list .row > div {
  padding-top: 3px;
  padding-bottom: 5px;
}
.event-list .row > div:last-child {
  padding-bottom: 0;
}
.event-list .row a:hover {
  background: #f3f3f3;
}
.event-list .image-event {
  overflow: hidden;
  border-radius: 10px;
  max-height: 80px;
}
.event-list .image-event img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-list .info h3,
.event-list .info p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098;
  margin-bottom: 0;
  padding-bottom: 0;
}
.event-list.games .info h2 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: normal;
  color: #678098;
  margin-bottom: 0;
}
.event-list .info h3 {
  font-weight: 700;
  font-size: 12px;
}
.event-list .info p {
  font-weight: 400;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-list.games .info p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
  white-space: initial;
}
.event-list .info .title-game {
  display: flex;
  flex-direction: column;
}
.event-list .info p.title {
  float: left;
  font-weight: 700;
  font-size: 20px;
}
.event-list .info p.sinopse {
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* END EVENT LIST */

/* MODAL */

.modal {
  z-index: 999999;
}
.modal-backdrop {
  z-index: 999998;
}

/* END MODAL */

/* GAME */

.filter-button {
  border: #f1f4f5 1px solid;
  border-radius: 5px;
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 4px;
  display: inline-block;
}

.filter-button .btn {
  padding: 0;
  margin: 0;
  color: #9068be;
  cursor: pointer;
}

.no-vote-value {
  opacity: 0.6;
}

.game-detail > .row > div:nth-child(2) {
  margin-bottom: 30px;
}
.game-detail .title-game {
  margin-bottom: 15px;
}
.game-detail .title-game h1 {
  color: #19113c;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.game-detail h3 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098 !important;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 0;
}
.game-detail .video {
  position: relative;
  overflow: hidden;
  padding-top: 350px;
  /* padding-top: 56.25%;
    -webkit-box-shadow: 6px 6px 20px -12px rgb(0 0 0 / 41%);
    -moz-box-shadow: 6px 6px 20px -12px rgba(0,0,0,0.41);
    box-shadow: 6px 6px 20px -12px rgb(0 0 0 / 41%); */
  margin-bottom: 15px;
  border-radius: 10px;
}
.game-detail .video iframe {
  position: absolute;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  max-height: 375px;
  border: 0;
  border-radius: 10px;
}
.game-detail .div {
  padding: 0 0 20px 0;
  margin: 0 0 30px 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.event-list.games .rating .btn-outline-warning,
.game-detail .rating .btn-outline-warning,
.content-feedback .rating .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
  padding: 0 4px 0 0;
}
.event-list.games .rating span,
.game-detail .rating span,
.content-feedback .rating span {
  color: #ffc107;
}
.game-detail header {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.game-detail header .card-title {
  margin-bottom: 0;
}
.menu-game {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.menu-game .items {
  width: 100%;
}
.menu-game .items ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.menu-game .items ul li {
  margin-left: 20px;
  text-align: center;
}
.menu-game .items ul li a, .menu-game .items ul li button {
  font-size: 12px;
}

.menu-game .items ul li button {
  margin: 0;
  padding: 0;
  color: #19113c;
}

.menu-game .items ul li button:hover {
  color: #9068be;
  text-decoration: none;
}


.menu-game .items ul li i {
  padding-right: 5px;
  transition: all 0.3s;
}
.menu-game .items ul li.active a,
.menu-game .items ul li.active a i,
.menu-game .items ul li a:hover,
.menu-game .items ul li a:hover i {
  color: #9068be !important;
}

/* END GAME */

/* AVALIAÇÃO GAME */

.avaliacao-game {
  text-align: right;
}
.avaliacao-game h2 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098 !important;
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: 400;
}
.avaliacao-game h2 strong {
  font-weight: 700;
}

/* TABLE */

.table-responsive td {
  font-weight: 400;
}
.table-responsive td,
.table-responsive th {
  line-height: normal;
}
.table-responsive.feedback tr:nth-child(1) td:nth-child(1) {
  border-top: 0;
}
.table-responsive.feedback thead th:nth-child(1),
.table-responsive.feedback tr td:nth-child(1) {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 57px;
}
.table-responsive.feedback thead th:nth-child(2),
.table-responsive.feedback tr td:nth-child(2) {
  width: 15%;
}
.table-responsive.feedback thead th:nth-child(3),
.table-responsive.feedback tr td:nth-child(3) {
  width: 15%;
}
.table-responsive.feedback thead th:nth-child(4),
.table-responsive.feedback tr td:nth-child(4) {
  width: 10%;
}
.table-responsive.feedback tr td small.label {
  display: none;
}
.table-right {
  text-align: right;
}
.botton-line {
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  align-items: center;
}

/* END TABLE */

/* PAGINATION */

.pagination-sm .page-link {
  color: #678098;
  width: 30px;
  height: 28px;
  padding: 5px;
  text-align: center;
  font-size: 12px;
}
.pagination-sm .page-link:hover,
.pagination-sm .disabled .page-link {
  background-color: #9068be;
  color: #fff;
}

/* END PAGINATION */

/* FEEDBACK */

.content-feedback {
  padding-top: 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 25px;
}
.content-feedback .row > div {
  margin-bottom: 20px;
}
.content-feedback h3,
.content-feedback p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098 !important;
  margin-bottom: 0;
  padding-bottom: 0;
}
.content-feedback h3 {
  font-weight: 700;
  font-size: 12px;
}
.content-feedback p {
  font-weight: 400;
}
.actions-feedback {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.actions-feedback button {
  margin-left: 10px;
}

/* END FEEDBACK */

/* CHAMADAS */

.content-chamada {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.content-chamada figure {
  width: 100%;
  height: auto;
  max-height: 300px;
  margin-bottom: 15px !important;
  overflow: hidden;
}
.content-chamada figure img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  transition: all 0.3s;
}
a:hover .content-chamada figure img {
  transform: scale(1.2) rotate(4deg);
}
.content-chamada .text-chamada h1 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: normal;
  color: #678098 !important;
  font-weight: 700;
}
.content-chamada .text-chamada h3 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098 !important;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 0;
}
.content-chamada .text-chamada h4 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #678098 !important;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 0;
}
.content-chamada .text-chamada p {
  color: #678098 !important;
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 3; */
  /* -webkit-box-orient: vertical; */
  height: auto;
  /* white-space: initial;
  overflow: hidden; */
}

/* ALERT */

.alert-message {
  background-color: #9068be;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-message i {
  font-size: 26px;
  margin-right: 10px;
}
.alert-message p {
  margin-bottom: 0;
}
.alert-message a {
  color: #fff;
  text-decoration: underline;
}
.alert-message a:hover {
  color: #19113c;
}

.alert-message-modal {
  max-width: 360px;
  margin: auto;
}
.alert-message-modal .modal-body {
  text-align: center;
}
.alert-message-modal .modal-body .mdi-alert {
  font-size: 40px;
}

/* FOOTER */

.footer {
  margin-bottom: 10px;
}
.footer .copyright {
  font-size: 11px;
}

/* RESPONSIVE */

@media (max-width: 982px) {
  /* HEADER */
  .nav-header {
    width: 90px !important;
  }

  /* MENU */
  .fix-sidebar.mini-nav .brand-logo a,
  .brand-logo a {
    width: 90px;
    margin: 10px 0 5px 0px;
  }
}
@media (max-width: 767px) {
  .form-custom .form-group .buttons {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }
  .form-custom .form-group .buttons button {
    margin: 10px 10px 30px 0;
  }

  .avaliacao-game {
    text-align: left;
    margin: 15px 0 0;
  }

  /* EVENT LIST */

  .event-list .image-event {
    padding: 0;
    max-height: 300px;
  }
  .event-list .info h3 {
    font-weight: 700;
    font-size: 11px;
  }

  /* TABLE */

  .table-responsive.feedback thead {
    display: none;
  }
  .table-responsive.feedback tr {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .table-responsive.feedback tr:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .table-responsive.feedback tr td {
    width: 100% !important;
    border: 0 !important;
    padding: 7px 0;
  }
  .table-responsive.feedback tr td:nth-child(1) {
    -webkit-line-clamp: 3;
    height: 72px;
  }
  .table-responsive.feedback tr td small.label {
    display: block;
    font-size: 11px;
    font-weight: 700;
    color: #678098;
    padding: 0 0 5px 0;
  }

  /* FOOTER */

  .footer .copyright {
    text-align: center;
  }
}
@media (min-width: 576px) {
  /* MODAL */
  .modal-dialog {
    max-width: 700px;
  }
}
@media (max-width: 575px) {
  /* TITLE */
  .page-titles {
    height: auto;
    width: 100%;
    float: left;
    display: block;
  }
  .page-titles .col {
    width: 100%;
    height: auto;
    margin: 0px 0 15px !important;
    float: left;
    display: block;
  }

  /* BREADCRUMB */

  .page-titles .breadcrumb {
    float: left;
  }

  /* FORM */

  .form-custom .checkbox {
    margin-left: 0;
  }

  .form-input-content .card .card-body {
    padding: 50px 30px;
  }
  .form-custom .checkbox {
    margin-left: 0;
  }
  .form-custom .content-radio {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-custom .content-radio .radio {
    width: 100%;
  }
  .form-group .row > div {
    width: 100% !important;
    max-width: 100% !important;
    flex: 100%;
    margin-bottom: 10px;
  }

  /* TABLE */

  .table-responsive.feedback tr td i {
    font-size: 24px;
    margin: 10px 0px 0 10px;
  }
  /* END TABLE */

  /* FEEDBACK */

  .actions-feedback {
    flex-direction: column;
  }
  .actions-feedback button {
    margin: 7px 0;
  }
  .actions-feedback button:last-of-type {
    margin-left: inherit;
  }

  /* END FEEDBACK */

  /* GAME DETAIL */

  .menu-game .items ul {
    flex-direction: column;
    justify-content: center;
  }
  .menu-game .items ul li {
    margin: 20px 0 0;
    text-align: left;
  }

  /* LOGIN */

  .form-input-content .card .card-body {
    padding: 30px 20px;
  }
  .login-discord .btn i,
  .login-discord .btn img {
    margin-right: 0;
  }
  .login-discord .btn {
    flex-direction: column;
    height: auto;
    font-size: 14px;
  }
  .login-bg .logo a img {
    max-width: 150px;
  }
}

.input-error,
.input-error h1,
.input-error h2,
.input-error h3,
.input-error h4 {
  color: red !important;
}

.input-error input,
.input-error textarea,
.input-error select,
.input-error .multi-select .dropdown-container {
  border: 1px solid red !important;
}

.multi-select .dropdown-container {
  min-height: 36px;
  height: 36px;
  background: #f1f4f5 !important;
  color: #000 !important;
}

.multi-select .dropdown-container .clear-selected-button {
  margin: 0px;
}
