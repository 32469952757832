.login-screen {
  height: 100vh;
}

.login-bg {
  background: url(../../assets/images/v2/Slide1.JPG) center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#login-page1 body::after {
  background: transparent;
}

.login-bg .logo {
  width: 170px;
  margin: auto;
}

.login-bg .btn-back {
  position: absolute;
  right: 15px;
  top: 10px;
}

.login-bg .btn-back a {
  display: block;
  font-size: 11px;
  color: #19113c;
}

.login-bg .btn-back a:hover {
  color: var(--accent-color);
}

.login-bg .btn-back a img {
  width: 4px;
  height: auto;
  margin-right: 5px;
}

.login-title {
  width: 100%;
  text-align: center;
}
