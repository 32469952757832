@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url(assets/icons/v2/font-awesome/css/font-awesome.min.css);
@import url(assets/icons/v2/simple-line-icons/css/simple-line-icons.css);
@import url(assets/icons/v2/weather-icons/css/weather-icons.min.css);
@import url(assets/icons/v2/cryptocoins/css/cryptocoins.css);
@import url(assets/icons/v2/cryptocoins/css/cryptocoins-colors.css);
@import url(assets/icons/v2/linea-icons/linea.css);
@import url(assets/icons/v2/ionicons/css/ionicons.css);
@import url(assets/icons/v2/themify-icons/themify-icons.css);
@import url(assets/icons/v2/flag-icon-css/flag-icon.min.css);
@import url(assets/icons/v2/material-design-iconic-font/materialdesignicons.min.css);
@import "assets/css/v2/bootstrap.min.css";
@import "assets/css/v2/shortcode.css";
@import "assets/css/v2/animate.css";
@import "assets/css/v2/metisMenu.min.css";
* {
  outline: none;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  overflow-x: hidden;
  font-size: 14px;
  height: 100%;
}

html {
  position: relative;
}

html,
body,
#root,
.App,
.login-screen,
.login-bg {
  min-height: 100% !important;
  position: relative;
}

.login-screen {
  background-color: #000;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
}

#main-wrapper.show {
  opacity: 1;
}

a {
  transition: all 0.4s ease-in-out;
}

a:focus,
a:hover {
  text-decoration: none;
}

a.link {
  color: #ddd;
}

a.link:focus,
a.link:hover {
  color: #9068be;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

div#modalFinalist button:disabled,
.rating-hide {
  display: none;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

.img-flag {
  height: 15px;
  width: 18px;
}

.img-full {
  width: 100%;
}

.mdi-set,
.mdi:before {
  line-height: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  line-height: 40px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 24px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

.img-circle {
  border-radius: 100%;
}

.content-body .container {
  padding: 45px 30px 0px;
}
.content-body .container-fluid {
  padding: 30px 30px 0px;
}

@media (max-width: 767px) {
  .content-body .container {
    padding: 15px 15px 0px;
  }
}
.c-pointer {
  cursor: pointer;
}

.rotate-90 {
  transform: rotate(90deg);
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.l-border-1 {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

.d-border-1 {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

.border-white-2 {
  border: 2px solid #fff;
}

.border-bottom-1 {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.border-top-1 {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.border-right-1 {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.border-left-1 {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.dark-overlay-1 {
  background: rgba(0, 0, 0, 0.1);
}

.dark-overlay-2 {
  background: rgba(0, 0, 0, 0.2);
}

.dark-overlay-3 {
  background: rgba(0, 0, 0, 0.3);
}

.dark-overlay-4 {
  background: rgba(0, 0, 0, 0.4);
}

.dark-overlay-5 {
  background: rgba(0, 0, 0, 0.5);
}

.dark-overlay-6 {
  background: rgba(0, 0, 0, 0.6);
}

.dark-overlay-7 {
  background: rgba(0, 0, 0, 0.7);
}

.dark-overlay-8 {
  background: rgba(0, 0, 0, 0.8);
}

.dark-overlay-9 {
  background: rgba(0, 0, 0, 0.9);
}

#preloader {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;
}

.loader {
  position: absolute;
  width: 50px;
  height: 100px;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
/*    Color Mixins
-------------------*/
.color-primary,
.text-primary {
  color: #9068be !important;
}

.color-secondary,
.text-secondary {
  color: #978ded !important;
}

.color-success,
.text-success {
  color: #6ed3cf !important;
}

.color-info,
.text-info {
  color: #f5a3e3 !important;
}

.color-danger,
.text-danger {
  color: #c43235 !important;
}

.color-warning,
.text-warning {
  color: #fae596 !important;
}

.color-dark,
.text-dark {
  color: #173e43 !important;
}

.color-muted,
.text-muted {
  color: #abafb3 !important;
}

/*    Background Mixins
--------------------------*/
.bg-primary {
  background-color: #9068be !important;
  color: #fff !important;
  fill: #9068be !important;
}

.bg-secondary {
  background-color: #978ded !important;
  color: #fff !important;
  fill: #978ded !important;
}

.bg-success {
  background-color: #6ed3cf !important;
  color: #fff !important;
  fill: #6ed3cf !important;
}

.bg-info {
  background-color: #f5a3e3 !important;
  color: #fff !important;
  fill: #f5a3e3 !important;
}

.bg-danger {
  background-color: #c43235 !important;
  color: #fff !important;
  fill: #c43235 !important;
}

.bg-warning {
  background-color: #fae596 !important;
  color: #fff !important;
  fill: #fae596 !important;
}

.bg-dark {
  background-color: #173e43 !important;
  color: #fff !important;
  fill: #173e43 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
  color: #173e43 !important;
  fill: #f8f9fa !important;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
  color: #000;
}

/*  Background Lighten
----------------------*/
.bg-primary-darken-5 {
  background-color: #130c1b;
}

.bg-secondary-darken-5 {
  background-color: #1a116a;
}

.bg-success-darken-5 {
  background-color: #0f3331;
}

.bg-info-darken-5 {
  background-color: #8a0f6f;
}

.bg-danger-darken-5 {
  background-color: black;
}

.bg-warning-darken-5 {
  background-color: #8a6f07;
}

/*    Border Mixins
--------------------------*/
.border-primary {
  border-color: #9068be;
}

.border-secondary {
  border-color: #978ded;
}

.border-success {
  border-color: #6ed3cf;
}

.border-info {
  border-color: #f5a3e3;
}

.border-danger {
  border-color: #c43235;
}

.border-warning {
  border-color: #fae596;
}

.border-dark {
  border-color: #173e43;
}

/*    Brand Background
-----------------------------*/
.bg-facebook {
  background: #3b5998;
  fill: #3b5998;
}

.bg-twitter {
  background: #1da1f2;
  fill: #1da1f2;
}

.bg-youtube {
  background: #cd201f;
  fill: #cd201f;
}

.bg-google-plus {
  background: #db4439;
  fill: #db4439;
}

.bg-linkedin {
  background: #007bb6;
  fill: #007bb6;
}

/*    Checkbox Color
-----------------------------*/
.chk-col-red[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #fb3a3a;
  border-right: 2px solid #fb3a3a;
}

.chk-col-pink[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #e91e63;
  border-right: 2px solid #e91e63;
}

.chk-col-purple[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #7460ee;
  border-right: 2px solid #7460ee;
}

.chk-col-deep-purple[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #673ab7;
  border-right: 2px solid #673ab7;
}

.chk-col-indigo[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #3f51b5;
  border-right: 2px solid #3f51b5;
}

.chk-col-blue[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #02bec9;
  border-right: 2px solid #02bec9;
}

.chk-col-light-blue[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #03a9f4;
  border-right: 2px solid #03a9f4;
}

.chk-col-cyan[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #00bcd4;
  border-right: 2px solid #00bcd4;
}

.chk-col-teal[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #009688;
  border-right: 2px solid #009688;
}

.chk-col-green[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #26c6da;
  border-right: 2px solid #26c6da;
}

.chk-col-light-green[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #8bc34a;
  border-right: 2px solid #8bc34a;
}

.chk-col-lime[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #cddc39;
  border-right: 2px solid #cddc39;
}

.chk-col-yellow[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #ffe821;
  border-right: 2px solid #ffe821;
}

.chk-col-amber[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #ffc107;
  border-right: 2px solid #ffc107;
}

.chk-col-orange[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #ff9800;
  border-right: 2px solid #ff9800;
}

.chk-col-deep-orange[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #ff5722;
  border-right: 2px solid #ff5722;
}

.chk-col-brown[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #795548;
  border-right: 2px solid #795548;
}

.chk-col-grey[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #9e9e9e;
  border-right: 2px solid #9e9e9e;
}

.chk-col-blue-grey[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #607d8b;
  border-right: 2px solid #607d8b;
}

.chk-col-black[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
}

.chk-col-white[type="checkbox"]:checked + label::before {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.filled-in.chk-col-red[type="checkbox"]:checked + label::after {
  background-color: #fb3a3a;
  border: 2px solid #fb3a3a;
}

.filled-in.chk-col-pink[type="checkbox"]:checked + label::after {
  background-color: #e91e63;
  border: 2px solid #e91e63;
}

.filled-in.chk-col-purple[type="checkbox"]:checked + label::after {
  background-color: #7460ee;
  border: 2px solid #7460ee;
}

.filled-in.chk-col-deep-purple[type="checkbox"]:checked + label::after {
  background-color: #673ab7;
  border: 2px solid #673ab7;
}

.filled-in.chk-col-indigo[type="checkbox"]:checked + label::after {
  background-color: #3f51b5;
  border: 2px solid #3f51b5;
}

.filled-in.chk-col-blue[type="checkbox"]:checked + label::after {
  background-color: #02bec9;
  border: 2px solid #02bec9;
}

.filled-in.chk-col-light-blue[type="checkbox"]:checked + label::after {
  background-color: #03a9f4;
  border: 2px solid #03a9f4;
}

.filled-in.chk-col-cyan[type="checkbox"]:checked + label::after {
  background-color: #00bcd4;
  border: 2px solid #00bcd4;
}

.filled-in.chk-col-teal[type="checkbox"]:checked + label::after {
  background-color: #009688;
  border: 2px solid #009688;
}

.filled-in.chk-col-green[type="checkbox"]:checked + label::after {
  background-color: #26c6da;
  border: 2px solid #26c6da;
}

.filled-in.chk-col-light-green[type="checkbox"]:checked + label::after {
  background-color: #8bc34a;
  border: 2px solid #8bc34a;
}

.filled-in.chk-col-lime[type="checkbox"]:checked + label::after {
  background-color: #cddc39;
  border: 2px solid #cddc39;
}

.filled-in.chk-col-yellow[type="checkbox"]:checked + label::after {
  background-color: #ffe821;
  border: 2px solid #ffe821;
}

.filled-in.chk-col-amber[type="checkbox"]:checked + label::after {
  background-color: #ffc107;
  border: 2px solid #ffc107;
}

.filled-in.chk-col-orange[type="checkbox"]:checked + label::after {
  background-color: #ff9800;
  border: 2px solid #ff9800;
}

.filled-in.chk-col-deep-orange[type="checkbox"]:checked + label::after {
  background-color: #ff5722;
  border: 2px solid #ff5722;
}

.filled-in.chk-col-brown[type="checkbox"]:checked + label::after {
  background-color: #795548;
  border: 2px solid #795548;
}

.filled-in.chk-col-grey[type="checkbox"]:checked + label::after {
  background-color: #9e9e9e;
  border: 2px solid #9e9e9e;
}

.filled-in.chk-col-blue-grey[type="checkbox"]:checked + label::after {
  background-color: #607d8b;
  border: 2px solid #607d8b;
}

.filled-in.chk-col-black[type="checkbox"]:checked + label::after {
  background-color: #000;
  border: 2px solid #000;
}

.filled-in.chk-col-white[type="checkbox"]:checked + label::after {
  background-color: #fff;
  border: 2px solid #fff;
}

.radio-col-red[type="radio"]:checked + label::after {
  background-color: #fb3a3a;
  border-color: #fb3a3a;
}

.radio-col-pink[type="radio"]:checked + label::after {
  background-color: #e91e63;
  border-color: #e91e63;
}

.radio-col-purple[type="radio"]:checked + label::after {
  background-color: #7460ee;
  border-color: #7460ee;
}

.radio-col-deep-purple[type="radio"]:checked + label::after {
  background-color: #673ab7;
  border-color: #673ab7;
}

.radio-col-indigo[type="radio"]:checked + label::after {
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.radio-col-blue[type="radio"]:checked + label::after {
  background-color: #02bec9;
  border-color: #02bec9;
}

.radio-col-light-blue[type="radio"]:checked + label::after {
  background-color: #03a9f4;
  border-color: #03a9f4;
}

.radio-col-cyan[type="radio"]:checked + label::after {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.radio-col-teal[type="radio"]:checked + label::after {
  background-color: #009688;
  border-color: #009688;
}

.radio-col-green[type="radio"]:checked + label::after {
  background-color: #26c6da;
  border-color: #26c6da;
}

.radio-col-light-green[type="radio"]:checked + label::after {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.radio-col-lime[type="radio"]:checked + label::after {
  background-color: #cddc39;
  border-color: #cddc39;
}

.radio-col-yellow[type="radio"]:checked + label::after {
  background-color: #ffe821;
  border-color: #ffe821;
}

.radio-col-amber[type="radio"]:checked + label::after {
  background-color: #ffc107;
  border-color: #ffc107;
}

.radio-col-orange[type="radio"]:checked + label::after {
  background-color: #ff9800;
  border-color: #ff9800;
}

.radio-col-deep-orange[type="radio"]:checked + label::after {
  background-color: #ff5722;
  border-color: #ff5722;
}

.radio-col-brown[type="radio"]:checked + label::after {
  background-color: #795548;
  border-color: #795548;
}

.radio-col-grey[type="radio"]:checked + label::after {
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}

.radio-col-blue-grey[type="radio"]:checked + label::after {
  background-color: #607d8b;
  border-color: #607d8b;
}

.radio-col-black[type="radio"]:checked + label::after {
  background-color: #000;
  border-color: #000;
}

.radio-col-white[type="radio"]:checked + label::after {
  background-color: #fff;
  border-color: #fff;
}

.with-gap.radio-col-red[type="radio"]:checked + label::before {
  border: 2px solid #fb3a3a;
}
.with-gap.radio-col-red[type="radio"]:checked + label::after {
  background-color: #fb3a3a;
  border: 2px solid #fb3a3a;
}

.with-gap.radio-col-pink[type="radio"]:checked + label::before {
  border: 2px solid #e91e63;
}
.with-gap.radio-col-pink[type="radio"]:checked + label::after {
  background-color: #e91e63;
  border: 2px solid #e91e63;
}

.with-gap.radio-col-purple[type="radio"]:checked + label::before {
  border: 2px solid #7460ee;
}
.with-gap.radio-col-purple[type="radio"]:checked + label::after {
  background-color: #7460ee;
  border: 2px solid #7460ee;
}

.with-gap.radio-col-deep-purple[type="radio"]:checked + label::before {
  border: 2px solid #673ab7;
}
.with-gap.radio-col-deep-purple[type="radio"]:checked + label::after {
  background-color: #673ab7;
  border: 2px solid #673ab7;
}

.with-gap.radio-col-indigo[type="radio"]:checked + label::before {
  border: 2px solid #3f51b5;
}
.with-gap.radio-col-indigo[type="radio"]:checked + label::after {
  background-color: #3f51b5;
  border: 2px solid #3f51b5;
}

.with-gap.radio-col-blue[type="radio"]:checked + label::before {
  border: 2px solid #02bec9;
}
.with-gap.radio-col-blue[type="radio"]:checked + label::after {
  background-color: #02bec9;
  border: 2px solid #02bec9;
}

.with-gap.radio-col-light-blue[type="radio"]:checked + label::before {
  border: 2px solid #03a9f4;
}
.with-gap.radio-col-light-blue[type="radio"]:checked + label::after {
  background-color: #03a9f4;
  border: 2px solid #03a9f4;
}

.with-gap.radio-col-cyan[type="radio"]:checked + label::before {
  border: 2px solid #00bcd4;
}
.with-gap.radio-col-cyan[type="radio"]:checked + label::after {
  background-color: #00bcd4;
  border: 2px solid #00bcd4;
}

.with-gap.radio-col-teal[type="radio"]:checked + label::before {
  border: 2px solid #009688;
}
.with-gap.radio-col-teal[type="radio"]:checked + label::after {
  background-color: #009688;
  border: 2px solid #009688;
}

.with-gap.radio-col-green[type="radio"]:checked + label::before {
  border: 2px solid #26c6da;
}
.with-gap.radio-col-green[type="radio"]:checked + label::after {
  background-color: #26c6da;
  border: 2px solid #26c6da;
}

.with-gap.radio-col-light-green[type="radio"]:checked + label::before {
  border: 2px solid #8bc34a;
}
.with-gap.radio-col-light-green[type="radio"]:checked + label::after {
  background-color: #8bc34a;
  border: 2px solid #8bc34a;
}

.with-gap.radio-col-lime[type="radio"]:checked + label::before {
  border: 2px solid #cddc39;
}
.with-gap.radio-col-lime[type="radio"]:checked + label::after {
  background-color: #cddc39;
  border: 2px solid #cddc39;
}

.with-gap.radio-col-yellow[type="radio"]:checked + label::before {
  border: 2px solid #ffe821;
}
.with-gap.radio-col-yellow[type="radio"]:checked + label::after {
  background-color: #ffe821;
  border: 2px solid #ffe821;
}

.with-gap.radio-col-amber[type="radio"]:checked + label::before {
  border: 2px solid #ffc107;
}
.with-gap.radio-col-amber[type="radio"]:checked + label::after {
  background-color: #ffc107;
  border: 2px solid #ffc107;
}

.with-gap.radio-col-orange[type="radio"]:checked + label::before {
  border: 2px solid #ff9800;
}
.with-gap.radio-col-orange[type="radio"]:checked + label::after {
  background-color: #ff9800;
  border: 2px solid #ff9800;
}

.with-gap.radio-col-deep-orange[type="radio"]:checked + label::before {
  border: 2px solid #ff5722;
}
.with-gap.radio-col-deep-orange[type="radio"]:checked + label::after {
  background-color: #ff5722;
  border: 2px solid #ff5722;
}

.with-gap.radio-col-brown[type="radio"]:checked + label::before {
  border: 2px solid #795548;
}
.with-gap.radio-col-brown[type="radio"]:checked + label::after {
  background-color: #795548;
  border: 2px solid #795548;
}

.with-gap.radio-col-grey[type="radio"]:checked + label::before {
  border: 2px solid #9e9e9e;
}
.with-gap.radio-col-grey[type="radio"]:checked + label::after {
  background-color: #9e9e9e;
  border: 2px solid #9e9e9e;
}

.with-gap.radio-col-blue-grey[type="radio"]:checked + label::before {
  border: 2px solid #607d8b;
}
.with-gap.radio-col-blue-grey[type="radio"]:checked + label::after {
  background-color: #607d8b;
  border: 2px solid #607d8b;
}

.with-gap.radio-col-black[type="radio"]:checked + label::before {
  border: 2px solid #000;
}
.with-gap.radio-col-black[type="radio"]:checked + label::after {
  background-color: #000;
  border: 2px solid #000;
}

.with-gap.radio-col-white[type="radio"]:checked + label::before {
  border: 2px solid #fff;
}
.with-gap.radio-col-white[type="radio"]:checked + label::after {
  background-color: #fff;
  border: 2px solid #fff;
}

.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-red:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(251, 58, 58, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-pink:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(233, 30, 99, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-purple:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(116, 96, 238, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-deep-purple:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(103, 58, 183, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-indigo:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(63, 81, 181, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-blue:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(2, 190, 201, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-light-blue:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(3, 169, 244, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-cyan:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(0, 188, 212, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-teal:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(0, 150, 136, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-green:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(38, 198, 218, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-light-green:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(139, 195, 74, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-lime:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(205, 220, 57, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-yellow:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(255, 232, 33, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-amber:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(255, 193, 7, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-orange:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(255, 152, 0, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-deep-orange:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(255, 87, 34, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-brown:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(121, 85, 72, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-grey:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(158, 158, 158, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-blue-grey:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(96, 125, 139, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-black:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.switch
  label
  input[type="checkbox"]:checked:not(:disabled)
  ~ .lever.switch-col-white:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(255, 255, 255, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-red {
  background-color: rgba(251, 58, 58, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-red::after {
  background-color: #fb3a3a;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-pink {
  background-color: rgba(233, 30, 99, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-pink::after {
  background-color: #e91e63;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-purple {
  background-color: rgba(116, 96, 238, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-purple::after {
  background-color: #7460ee;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-deep-purple {
  background-color: rgba(103, 58, 183, 0.5);
}
.switch
  label
  input[type="checkbox"]:checked
  + .lever.switch-col-deep-purple::after {
  background-color: #673ab7;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-indigo {
  background-color: rgba(63, 81, 181, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-indigo::after {
  background-color: #3f51b5;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue {
  background-color: rgba(2, 190, 201, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue::after {
  background-color: #02bec9;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-light-blue {
  background-color: rgba(3, 169, 244, 0.5);
}
.switch
  label
  input[type="checkbox"]:checked
  + .lever.switch-col-light-blue::after {
  background-color: #03a9f4;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-cyan {
  background-color: rgba(0, 188, 212, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-cyan::after {
  background-color: #00bcd4;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-teal {
  background-color: rgba(0, 150, 136, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-teal::after {
  background-color: #009688;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-green {
  background-color: rgba(38, 198, 218, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-green::after {
  background-color: #26c6da;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-light-green {
  background-color: rgba(139, 195, 74, 0.5);
}
.switch
  label
  input[type="checkbox"]:checked
  + .lever.switch-col-light-green::after {
  background-color: #8bc34a;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-lime {
  background-color: rgba(205, 220, 57, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-lime::after {
  background-color: #cddc39;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-yellow {
  background-color: rgba(255, 232, 33, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-yellow::after {
  background-color: #ffe821;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-amber {
  background-color: rgba(255, 193, 7, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-amber::after {
  background-color: #ffc107;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-orange {
  background-color: rgba(255, 152, 0, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-orange::after {
  background-color: #ff9800;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-deep-orange {
  background-color: rgba(255, 87, 34, 0.5);
}
.switch
  label
  input[type="checkbox"]:checked
  + .lever.switch-col-deep-orange::after {
  background-color: #ff5722;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-brown {
  background-color: rgba(121, 85, 72, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-brown::after {
  background-color: #795548;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-grey {
  background-color: rgba(158, 158, 158, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-grey::after {
  background-color: #9e9e9e;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue-grey {
  background-color: rgba(96, 125, 139, 0.5);
}
.switch
  label
  input[type="checkbox"]:checked
  + .lever.switch-col-blue-grey::after {
  background-color: #607d8b;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-black {
  background-color: rgba(0, 0, 0, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-black::after {
  background-color: #000000;
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-white {
  background-color: rgba(255, 255, 255, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-white::after {
  background-color: #ffffff;
}

/*    Header
---------------*/
.icons a {
  position: relative;
}

.icons.active .drop-down {
  display: block;
}

.drop-down {
  display: none;
  z-index: 9999;
  width: 275px;
  position: absolute;
  right: 0;
  top: 60px;
  transform: translateY(50px);
  transition: all 0.4s ease-in-out;
  border-top: 0;
  border: 1px solid rgba(120, 130, 140, 0.13);
}

.dropdown-content-heading {
  padding: 15px 15px;
  line-height: 1.25;
}
.dropdown-content-heading span {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.dropdown-content-body ul > li {
  float: none;
  padding: 10px 15px;
  line-height: 1.25;
  padding: 10px 15px;
}
.dropdown-content-body ul > li:last-child {
  padding: 10px 15px;
  line-height: 1.25;
}

.notification-heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
}

.avatar-img {
  border-radius: 100px;
  width: 40px;
  position: relative;
  top: -3px;
}

.user-avatar {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 700;
}

.notification-text {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
  line-height: 1.25;
  font-weight: 400;
}

.notification-timestamp {
  font-size: 12px;
}

.notification-percentage {
  font-size: 12px;
  position: relative;
  top: 12px;
}

.more-link {
  display: inline-block;
}

.dropdown-profile {
  width: 160px;
}
.dropdown-profile li {
  padding: 7px 15px;
}
.dropdown-profile li a {
  display: block;
}
.dropdown-profile li a i {
  margin-right: 5px;
  font-size: 16px;
  position: relative;
  top: 2px;
}
.dropdown-profile li a span {
  font-family: "Poppins", sans-serif;
}
.dropdown-profile li:last-child {
  padding: 7px 15px;
}

.dropdown-task .progress {
  box-shadow: none;
  height: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.dropdown-task .progress-bar {
  box-shadow: none;
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}
svg.pulse-svg .first-circle,
svg.pulse-svg .third-circle,
svg.pulse-svg .second-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}
svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border-radius: 25px;
  height: 5px;
  position: absolute;
  right: 0px;
  top: -4px;
  width: 5px;
}
.pulse-css:after,
.pulse-css:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -2px;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}
@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}
.fix-header .header {
  position: fixed;
}
.fix-header .content-body {
  padding-top: 60px;
}

.fix-sidebar .nk-sidebar {
  position: fixed;
}
.fix-sidebar .nk-sidebar.mini-nav {
  position: absolute;
}

.header {
  width: 100%;
  height: 60px;
  z-index: 99999999;
  position: relative;
  padding: 0px 15px;
}

.nav-header {
  height: 60px;
  width: 250px;
  display: inline-block;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.nav-header .brand-logo a {
  padding: 10px 10px;
  display: block;
}
.nav-header .brand-logo a i {
  font-size: 20px;
  font-size: 22px;
}
.nav-header .brand-logo a b img {
  max-width: 25px;
}
.nav-header .brand-logo a span {
  font-size: 18px;
  margin-left: 7px;
}
.nav-header .brand-title img {
  max-width: 120px;
  margin-top: 2px;
}

.nav-control {
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: 10px;
}

.header-content {
  margin-left: 250px;
}

.footer {
  margin-left: 250px;
  position: absolute;
  left: 0;
  right: 0;
}
.footer .copyright {
  padding: 15px;
}
.footer .copyright p {
  margin: 0px;
}

.header-left {
  float: left;
  margin-left: 35px;
  line-height: 60px;
}
.header-left .icons {
  display: inline-block;
  float: left;
  margin: 0;
  padding: 0 5px;
  position: relative;
}
.header-left .icons > a {
  padding: 0 5px;
}
.header-left .drop-down {
  left: 0;
  top: 7px;
}

.header-search {
  min-width: 250px;
  box-shadow: 0px 0px 5px rgba(120, 130, 140, 0.13);
}
.header-search .input-group {
  margin-bottom: 0px !important;
}
.header-search .input-group .form-control:hover,
.header-search .input-group .form-control:focus,
.header-search .input-group .form-control.active {
  box-shadow: 0px;
}
.header-search .input-group .input-group-text {
  border-radius: 0px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  background: transparent;
}

.header-right {
  float: right;
  line-height: 60px;
}
.header-right .icons {
  display: inline-block;
  float: left;
  /* list-style: outside none none; */
  margin: 0;
  padding: 0 5px;
  position: relative;
}
.header-right .icons > a {
  padding: 0 5px;
}

.nav-user {
  text-align: center;
  margin-bottom: 10px;
  padding: 20px 15px 15px;
}
.nav-user img {
  width: 50px;
  height: 50px;
}
.nav-user h5 {
  margin-top: 10px;
  margin-bottom: 3px;
}
.nav-user p {
  margin-bottom: 8px;
}
.nav-user .nav-user-option {
  position: relative;
}
.nav-user .nav-user-option .dropdown-menu {
  left: 30px !important;
  top: 22px !important;
  padding: 10px 0px;
}
.nav-user .nav-user-option .dropdown-menu a {
  line-height: 27px;
}
.nav-user .nav-user-option .setting-option {
  display: inline-block;
  cursor: pointer;
}
.nav-user .nav-user-option .setting-option i {
  font-size: 16px;
  padding: 0px 5px;
}
.nav-user .nav-user-option .notification-option {
  display: inline-block;
  cursor: pointer;
}
.nav-user .nav-user-option .notification-option i {
  font-size: 16px;
  padding: 0px 5px;
}

.nav-label {
  padding: 13px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.nav-badge {
  position: absolute;
  right: 30px;
  top: 16px;
}

.content-body {
  margin-left: 250px;
  z-index: 0;
}

.nk-sidebar {
  width: 250px;
  height: 100%;
  position: absolute;
  top: 60px;
  padding-top: 0px;
  z-index: 1001;
  padding-bottom: 70px;
}
.nk-sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nk-sidebar .metismenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.nk-sidebar .metismenu > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.nk-sidebar .metismenu > li a > i {
  font-size: 16px;
  vertical-align: middle;
  padding: 0px 7px;
}
.nk-sidebar .metismenu a {
  position: relative;
  display: block;
  padding: 13px 15px;
  outline-width: 0;
  transition: all 0.3s ease-out;
}
.nk-sidebar .metismenu ul a {
  padding: 10px 15px 10px 45px;
}
.nk-sidebar .metismenu ul ul a {
  padding: 10px 15px 10px 60px;
}
.nk-sidebar .metismenu a {
  transition: all 0.4s ease-in-out;
}
.nk-sidebar .metismenu a:hover,
.nk-sidebar .metismenu a:focus,
.nk-sidebar .metismenu a:active {
  text-decoration: none;
}
.nk-sidebar .metismenu .has-arrow:after {
  transform: rotate(135deg) translateY(-50%);
}

@media (min-width: 768px) {
  .mini-nav .nav-header {
    width: 60px;
  }
  .mini-nav .nav-header .brand-logo b img {
    margin-top: 7px;
  }
  .mini-nav .nav-header .brand-title {
    display: none;
  }
  .mini-nav .header-content {
    margin-left: 60px;
  }
  .mini-nav .footer {
    margin-left: 60px;
  }
  .mini-nav .footer {
    margin-left: 60px;
  }
  .mini-nav .nav-user {
    padding: 20px 15px 15px;
  }
  .mini-nav .nav-user img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }
  .mini-nav .nav-user h5,
  .mini-nav .nav-user p {
    display: none;
  }
  .mini-nav .nav-user i {
    margin-top: 15px;
    display: block;
  }
  .mini-nav .nav-user .dropdown-menu {
    left: 45px !important;
    top: 22px !important;
  }
  .mini-nav .content-body {
    margin-left: 60px;
  }
  .mini-nav .nk-sidebar {
    width: 60px;
    overflow: visible;
    position: absolute;
  }
  .mini-nav .nk-sidebar .nav-text {
    display: none;
  }
  .mini-nav .nk-sidebar .slimScrollDiv,
  .mini-nav .nk-sidebar .nk-nav-scroll {
    overflow: visible !important;
  }
  .mini-nav .nk-sidebar .metismenu li {
    position: relative;
  }
  .mini-nav .nk-sidebar .metismenu li > ul {
    position: absolute;
    left: 60px;
    top: 47px;
    width: 190px;
    z-index: 1001;
    display: none;
    padding-left: 1px;
  }
  .mini-nav .nk-sidebar .metismenu > li {
    transition: all 0.4s ease-in-out;
  }
  .mini-nav .nk-sidebar .metismenu > li:hover > a {
    width: 250px;
    background: #343a40;
  }
  .mini-nav .nk-sidebar .metismenu > li:hover > a .nav-text {
    display: inline-block;
    padding-left: 27px;
  }
  .mini-nav .nk-sidebar .metismenu > li:hover > ul {
    display: block;
    height: auto !important;
    overflow: auto;
  }
  .mini-nav .nk-sidebar .metismenu > li:hover > ul a {
    padding: 10px 15px 10px 15px;
    margin-left: -1px;
  }
  .mini-nav .nk-sidebar .metismenu > li:hover > ul ul a {
    padding: 10px 15px 10px 30px;
    margin-left: -1px;
  }
  .mini-nav .nk-sidebar .metismenu .has-arrow:after {
    display: none;
  }
  .mini-nav .nk-sidebar .metismenu .nav-label,
  .mini-nav .nk-sidebar .metismenu .nav-badge {
    display: none;
  }
}
@media (max-width: 767px) {
  .header {
    position: fixed;
  }

  .nav-header {
    width: 60px;
  }
  .nav-header .brand-title {
    display: none;
  }

  .header-content {
    margin-left: 60px;
  }

  .footer {
    margin-left: 0px;
  }

  .nk-sidebar {
    transition: all 0.5s ease-in-out;
    position: absolute;
    left: 0;
  }

  .mini-nav .nk-sidebar {
    left: -100%;
  }

  .content-body {
    margin-left: 0px;
    padding-top: 60px;
  }
}
.compact-nav .nav-header {
  width: 150px;
}
.compact-nav .nk-sidebar {
  width: 150px;
}
.compact-nav .metismenu li {
  text-align: center;
}
.compact-nav .metismenu li a {
  padding: 10px 15px 10px 15px;
}
.compact-nav .nav-text {
  display: block;
  margin-top: 5px;
}
.compact-nav .nav-badge {
  display: none;
}
.compact-nav .has-arrow:after {
  display: none;
}
.compact-nav .header-content {
  margin-left: 150px;
}
.compact-nav .footer {
  margin-left: 150px;
}
.compact-nav .content-body {
  margin-left: 150px;
}

.compact-nav.mini-nav .nav-header,
.compact-nav.mini-nav .nk-sidebar {
  width: 60px;
}
.compact-nav.mini-nav .header-content {
  margin-left: 60px;
}
.compact-nav.mini-nav .footer {
  margin-left: 60px;
}
.compact-nav.mini-nav .metismenu li {
  text-align: left;
}
.compact-nav.mini-nav .content-body {
  margin-left: 60px;
}

@media (max-width: 767px) {
  .compact-nav .nav-header {
    width: 60px;
  }
  .compact-nav .content-body {
    margin-left: 0px;
  }

  .compact-nav.mini-nav .content-body {
    margin-left: 0px;
  }
}
.mega-list-title {
  display: none !important;
}

@media (min-width: 992px) {
  .horizontal-nav #main-wrapper {
    max-width: 1140px;
    margin: 0 auto;
  }
  .horizontal-nav .nk-sidebar {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
  }
  .horizontal-nav .nk-sidebar .slimScrollDiv {
    overflow: visible !important;
  }
  .horizontal-nav .nk-sidebar .slimScrollDiv .nk-nav-scroll {
    overflow: visible !important;
  }
  .horizontal-nav .nk-sidebar .nav-header {
    border-bottom: 0px !important;
  }
  .horizontal-nav .nk-sidebar .nav-user,
  .horizontal-nav .nk-sidebar .nav-label {
    display: none;
  }
  .horizontal-nav .nk-sidebar .metismenu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .horizontal-nav .nk-sidebar .metismenu li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
  }
  .horizontal-nav .nk-sidebar .metismenu li > ul {
    position: absolute;
    height: auto !important;
    top: 50px;
    width: 100%;
    min-width: 12rem;
    z-index: 999;
    left: 0;
    right: 0;
  }
  .horizontal-nav .nk-sidebar .metismenu li > ul a {
    padding: 10px 15px 1px 15px;
    margin-left: -0.1rem;
  }
  .horizontal-nav .nk-sidebar .metismenu li > ul ul a {
    padding: 10px 15px 1px 15px;
    margin-left: -1px;
  }
  .horizontal-nav .nk-sidebar .metismenu > li {
    flex: 0 0 auto;
  }
  .horizontal-nav .nk-sidebar .metismenu > li > a .nav-badge {
    display: none;
  }
  .horizontal-nav .nk-sidebar .metismenu > li > a:after {
    display: none;
  }
  .horizontal-nav .nk-sidebar .metismenu > li:hover > ul {
    display: block !important;
  }
  .horizontal-nav .nk-sidebar .metismenu > li.mega-menu {
    position: static;
  }
  .horizontal-nav .nk-sidebar .metismenu > li.mega-menu .mega-list-title {
    display: block !important;
    color: #454545;
    font-weight: 600;
  }
  .horizontal-nav .nk-sidebar .metismenu > li > ul > li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }
  .horizontal-nav
    .nk-sidebar
    .metismenu
    > li:last-child
    > ul
    > li:hover
    ul.collapse {
    right: auto !important;
    left: -100% !important;
  }
  .horizontal-nav .nk-sidebar .metismenu .collapse.in {
    display: none;
  }
  .horizontal-nav .nav-control {
    display: none;
  }
  .horizontal-nav .content-body {
    margin-left: 0;
  }
  .horizontal-nav .content-body .container {
    padding: 15px 0px 0px;
  }
  .horizontal-nav .page-titles {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
  }
  .horizontal-nav .footer {
    margin-left: 0;
    max-width: 1140px;
    margin: 0 auto;
  }
}
.hamburger {
  display: inline-block;
  left: 0px;
  position: relative;
  top: 5px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 37px;
  z-index: 999;
}

.hamburger .line {
  display: block;
  height: 2px;
  margin: 5px auto;
  width: 22px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

/*
    .hamburger.is-active {
      left: 60px;
    }
    */
.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 10px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(-6px);
  transform: translateX(-6px);
  width: 16px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg);
}

/*    Widget Two
---------------------------*/
.stat-widget-one {
  padding: 20px;
  text-align: center;
}
.stat-widget-one .stat-digit {
  font-size: 40px;
  font-weight: 700;
}
.stat-widget-one .stat-text {
  font-size: 20px;
  margin-bottom: 5px;
}
.stat-widget-one .progress {
  height: 8px;
  margin-bottom: 0;
  margin-top: 20px;
  box-shadow: none;
}
.stat-widget-one .progress-bar {
  box-shadow: none;
}

.avatar-group .avatar {
  border-radius: 50px;
  box-shadow: 0 0 0 5px #fff;
  display: inline-block;
  margin-left: -5px;
}
.avatar-group a.btn {
  margin-left: -5px;
}

.timeline {
  list-style: none;
  padding: 8px 0 8px;
  position: relative;
}
.timeline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  left: 25px;
  margin-right: -1.5px;
}
.timeline > li {
  margin-bottom: 5px;
  position: relative;
}
.timeline > li:before {
  content: " ";
  display: table;
}
.timeline > li:after {
  content: " ";
  display: table;
  clear: both;
}
.timeline > li > .timeline-panel {
  width: calc(100% - 35px);
  float: right;
  border-radius: 2px;
  padding: 5px 20px;
  position: relative;
}
.timeline > li > .timeline-badge {
  border-radius: 50%;
  height: 15px;
  left: 19px;
  position: absolute;
  top: 10px;
  width: 15px;
}

.timeline-body > p {
  font-size: 12px;
}

.timeline-badge.primary {
  background-color: #9068be !important;
}
.timeline-badge.success {
  background-color: #6ed3cf !important;
}
.timeline-badge.warning {
  background-color: #fae596 !important;
}
.timeline-badge.danger {
  background-color: #c43235 !important;
}
.timeline-badge.info {
  background-color: #f5a3e3 !important;
}

.social-icon1 ul li {
  display: inline-block;
}
.social-icon1 ul li a {
  display: block;
}
.social-icon1 ul li a i {
  font-size: 14px;
  padding: 10px 7px;
}

.social-icon2 a {
  border: 1px solid #9068be;
  display: block;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.social-icon3 ul li {
  display: inline-block;
}
.social-icon3 ul li a {
  display: block;
}
.social-icon3 ul li a i {
  font-size: 14px;
  padding: 10px 7px;
  color: #454545;
  transform: all 0.4s ease-in-out;
}
.social-icon3 ul li a:hover i {
  color: #9068be;
}

.countdown-content {
  padding: 100px 0;
}
.countdown-content h1 {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 50px;
}
.countdown-content p {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 50px;
  line-height: 30px;
}

.timer .days,
.timer .hours,
.timer .minutes,
.timer .seconds {
  font-weight: 600;
  font-size: 40px;
}
.timer .days span,
.timer .hours span,
.timer .minutes span,
.timer .seconds span {
  font-size: 18px;
  font-weight: 400;
}

.countdown-right-content {
  margin-top: 50px;
}
.countdown-right-content h6 {
  margin: 15px 0px;
}
.countdown-right-content .progress {
  height: 20px;
}

.countdown-token-card {
  margin-top: 30px;
}
.countdown-token-card .card {
  border: 0px !important;
  padding-top: 10px;
  text-align: center;
}
.countdown-token-card .card h2 {
  font-weight: 700;
}

.coin-exchange .input-group-prepend {
  min-width: 100px;
}
.coin-exchange .input-group-prepend .drop-menu {
  border-radius: 0px;
}
.coin-exchange .input-group-prepend .drop-menu .select {
  padding: 15px 10px;
}

#morris-exchange-chart {
  height: 300px;
}

.timeline-header {
  height: auto;
  text-align: center;
  padding: 2.3rem 1rem 2.3rem 1rem;
  position: relative;
}

#timeline {
  position: relative;
  display: table;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
}
#timeline div:after {
  content: "";
  width: 2px;
  position: absolute;
  top: 0.5rem;
  bottom: 0rem;
  left: 60px;
  z-index: 1;
}
#timeline h3 {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}
@media (min-width: 62em) {
  #timeline h3 {
    font-size: 1.1em;
  }
}
#timeline section.year {
  position: relative;
}
#timeline section.year:first-child section {
  margin-top: -1.3em;
  padding-bottom: 0px;
}
#timeline section.year section {
  position: relative;
  padding-bottom: 1.25em;
  margin-bottom: 2.2em;
}
#timeline section.year section h4 {
  position: absolute;
  bottom: 0;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  padding: 0 0 0 89px;
}
@media (min-width: 62em) {
  #timeline section.year section h4 {
    font-size: 1em;
  }
}
#timeline section.year section ul {
  list-style-type: none;
  padding: 0 0 0 75px;
  margin: -1.35rem 0 1em;
  max-width: 32rem;
  font-size: 1em;
}
@media (min-width: 62em) {
  #timeline section.year section ul {
    font-size: 1.1em;
    padding: 0 0 0 81px;
  }
}
#timeline section.year section ul:last-child {
  margin-bottom: 0;
}
#timeline section.year section ul:first-of-type:after {
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 55px;
  top: 3px;
  z-index: 2;
}
#timeline section.year section ul li {
  margin-left: 0.5rem;
}
#timeline section.year section ul li:before {
  content: "·";
  margin-left: -0.5rem;
  padding-right: 0.3rem;
}
#timeline section.year section ul li:not(:first-child) {
  margin-top: 0.5rem;
}
#timeline section.year section ul li span.price {
  font-weight: 500;
}

#price {
  display: inline;
}

.flex-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
}

.input {
  width: 25px;
  height: 25px;
  position: relative;
  border-radius: 50%;
}
.input:hover {
  cursor: pointer;
}
.input::before,
.input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  width: 4vw;
  height: 5px;
  max-width: 50px;
}
.input::before {
  left: calc(-4vw + 12.5px);
}
.input::after {
  right: calc(-4vw + 12.5px);
}
.input.active span {
  font-weight: 700;
}
.input.active span::before {
  font-size: 13px;
}
.input.active span::after {
  font-size: 15px;
}
.input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.input span::before {
  visibility: visible;
  position: absolute;
  left: 50%;
}
.input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
  content: attr(data-year);
  top: 25px;
  transform: translateX(-50%);
  font-size: 14px;
}
.input span::before {
  content: attr(data-info);
  top: -65px;
  width: 70px;
  transform: translateX(-5px) rotateZ(-45deg);
  font-size: 12px;
  text-indent: -10px;
}

.description-flex-container {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;
  width: 80vw;
}
.description-flex-container p {
  margin-top: 0;
  display: none;
}
.description-flex-container p.active {
  display: block;
}

@media (min-width: 1250px) {
  .input::before {
    left: -37.5px;
  }
  .input::after {
    right: -37.5px;
  }
}
@media (max-width: 850px) {
  .input {
    width: 17px;
    height: 17px;
  }
  .input::before,
  .input::after {
    height: 3px;
  }
  .input::before {
    left: calc(-4vw + 8.5px);
  }
  .input::after {
    right: calc(-4vw + 8.5px);
  }
}
@media (max-width: 600px) {
  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 15vh;
  }

  .input {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
  }
  .input::before,
  .input::after {
    content: none;
  }
  .input span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .input span::before {
    top: calc(100% + 5px);
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }
  .input span::after {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .description-flex-container {
    margin-top: 30px;
    text-align: center;
  }
}
.user-profile-name {
  display: inline-block;
  font-size: 28px;
  font-weight: 500;
  padding: 0px 15px;
}

.user-Location {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.user-Location i {
  font-size: 14px;
}

.user-job-title {
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.ratings h4 {
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 2px;
  padding-left: 15px;
}
.ratings span {
  margin-right: 10px;
}

.rating-star {
  margin-bottom: 10px;
  padding-left: 15px;
}

.user-send-message {
  margin-top: 15px;
  padding-left: 15px;
}

.contact-information,
.basic-information {
  margin: 15px;
}
.contact-information h4,
.basic-information h4 {
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 15px;
}

.contact-title {
  display: inline-block;
  padding-bottom: 15px;
  width: 170px;
  font-size: 16px;
}

.phone-number,
.mail-address,
.contact-email,
.contact-website,
.contact-skype,
.birth-date,
.gender {
  font-size: 16px;
}

.profile-widget {
  margin: 8px 0;
  text-align: center;
}
.profile-widget .stat-text {
  padding-bottom: 6px;
}

.gateway {
  text-align: center;
  margin-bottom: 10px;
}
.gateway .gateway-img {
  padding-top: 15px;
  height: 70px;
  margin: 0 auto 35px;
  max-width: 200px;
}
.gateway a {
  display: inline-block;
  padding: 5px 30px;
  margin-right: 15px;
}
.gateway .custom-checkbox {
  display: inline-block;
}

.add-wallet {
  border: 1px dashed rgba(120, 130, 140, 0.13);
  text-align: center;
}
.add-wallet a {
  padding: 30px 15px;
  display: block;
}
.add-wallet a i {
  font-size: 24px;
}
.add-wallet a p {
  margin: 0px;
  font-size: 18px;
}

.wallet-card .wallet-logo i {
  font-size: 30px;
  position: relative;
  top: 3px;
}
.wallet-card .wallet-logo h4 {
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
}
.wallet-card .wallet-address {
  margin: 20px 0px;
}
.wallet-card .wallet-address h5 {
  margin-bottom: 15px;
  font-size: 18px;
}
.wallet-card .wallet-address .form-control {
  background: transparent !important;
  height: 45px;
}
.wallet-card .wallet-info {
  margin-bottom: 10px;
}
.wallet-card .wallet-info p {
  margin-bottom: 7px;
  font-size: 15px;
}
.wallet-card .wallet-info .btn {
  margin-top: 20px;
  margin-right: 10px;
  padding: 7px 30px;
}

.cryptocompare-logo {
  display: none;
}

.chartTypeTabLinks {
  font-size: 16px !important;
  margin-bottom: 10px;
  margin-right: 10px !important;
}

.tabContainer {
  text-align: center;
}
.tabContainer .tablinks {
  border: 1px solid rgba(120, 130, 140, 0.13);
  display: inline-block;
  float: none;
  font-size: 16px;
  margin: 10px 4px;
  padding: 5px 30px;
}

.cccCustomRadioContainer span.radio::before {
  display: none;
}

.email-left-box {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
}

.email-right-box {
  margin-left: 250px;
}

.email-list {
  display: block;
  padding-left: 0;
}
.email-list .message {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  cursor: default;
  transition-duration: 0.3s;
}
.email-list .message:hover {
  transition-duration: 0.05s;
}
.email-list .message .col-mail {
  float: left;
  position: relative;
}
.email-list .message .col-mail-1 {
  width: 90px;
}
.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 18px;
  font-size: 16px;
  margin-left: 5px;
}
.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 15px 10px 0 20px;
}
.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: 4px solid transparent;
  border-radius: 100px;
  margin: 22px 26px 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}
.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 90px;
  right: 0;
  bottom: 0;
}
.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  padding-left: 80px;
}

.email-checkbox {
  cursor: pointer;
  height: 15px;
  width: 15px;
  position: relative;
  display: inline-block;
  border-radius: 1px;
  position: relative;
  top: 5px;
}
.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}
.email-checkbox input:checked label {
  opacity: 1;
}
.email-checkbox label {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.mail-list a {
  vertical-align: middle;
  padding: 10px 15px;
  display: block;
  background: transparent;
}

@media (max-width: 648px) {
  .email-left-box {
    width: 100%;
  }

  .email-right-box {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .email-list .message .col-mail-1 {
    width: 900px;
  }
  .email-list .message .col-mail-1 .title {
    left: 80px;
  }
  .email-list .message .col-mail-2 {
    left: 90px;
  }
  .email-list .message .col-mail-2 .subject {
    right: 0;
  }
  .email-list .message .col-mail-2 .date {
    display: none;
  }
  .content-btn {
    flex-direction: column;
  }
  .content-btn .btn {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
}
.photo-content {
  position: relative;
}
.photo-content .cover-photo {
  /* background: url(../../assets/images/profile/cover.jpg); */
  background-size: cover;
  background-position: center;
  min-height: 250px;
  width: 100%;
}
.photo-content .profile-photo {
  bottom: -75px;
  left: 100px;
  max-width: 150px;
  position: absolute;
}

.profile-info {
  padding: 30px 20px;
  margin-bottom: 30px;
}
.profile-info .prf-col {
  min-width: 300px;
  padding: 10px 50px 0;
}

.profile-interest .row {
  margin: 0px -7px;
}
.profile-interest .row .int-col {
  padding: 0px 7px;
}
.profile-interest .row .int-col .interest-cat {
  margin-bottom: 14px;
  position: relative;
  display: block;
}
.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  bottom: 10px;
  left: 10px;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 12px;
}

.profile-tab .nav-item .nav-link {
  font-size: 18px;
  padding: 10px 0px 5px;
  margin-right: 30px;
  transition: all 0.5s ease-in-out;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid transparent;
}
.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0px;
  background: transparent;
}

.post-input .form-control {
  height: 75px;
  font-weight: 400;
  margin: 15px 0px;
}
.post-input a {
  display: inline-block;
  font-size: 22px;
}
.post-input a i {
  display: block;
  margin-right: 15px;
  padding: 7px 20px;
  border-radius: 5px;
}
.post-input .btn {
  padding: 7px 30px;
  font-size: 14px;
  margin-top: -10px;
}

.profile-uoloaded-post {
  margin-top: 30px;
}
.profile-uoloaded-post img {
  margin-bottom: 20px;
}
.profile-uoloaded-post a h4 {
  margin-bottom: 10px;
}

/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
}

.fc-widget-header {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

.fc-widget-content {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

.fc th.fc-widget-header {
  background: rgba(120, 130, 140, 0.13) !important;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-button {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
  text-transform: capitalize;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}

.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}

#drop-remove {
  margin: 0;
  top: 3px;
}

#add-category .modal-header,
#event-modal .modal-header {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 600px;
}

.fc-view.fc-month-view.fc-basic-view,
.fc-view.fc-agendaWeek-view.fc-agenda-view,
.fc-view.fc-agendaDay-view.fc-agenda-view {
  overflow-y: scroll;
}

.fc-view-container .fc-view table {
  min-width: 450px;
}

.breadcrumb {
  margin-bottom: 0;
}

.page-titles {
  margin: 0 0 30px;
  position: relative;
  z-index: -1;
}
.page-titles h4 {
  margin-bottom: 0;
  margin-top: 0;
}
.page-titles h4 span {
  font-size: 14px;
  font-weight: 400;
}
.page-titles .breadcrumb {
  padding: 0px;
  background: transparent;
  font-size: 14px;
  float: right;
}
.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
}
.page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "";
  font-family: themify;
  font-size: 11px;
}

.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-sm {
  font-size: 12px;
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #6ed3cf;
}

.badge-info {
  background-color: #f5a3e3;
}

.badge-primary {
  background-color: #9068be;
}

.badge-warning {
  background-color: #fae596;
}

.badge-danger {
  background-color: #c43235;
}

.badge-dark {
  background-color: #173e43;
}

.badge-light {
  background-color: #f8f9fa;
}

.label {
  padding: 7px 10px;
  line-height: 15px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 85%;
}

.label-pill {
  border-radius: 10rem;
  padding-left: 0.6em;
  padding-right: 0.6em;
}

.label-rounded {
  border-radius: 60px;
}

.label-secondary {
  background-color: #9068be;
}

.label-primary {
  background-color: #978ded;
}

.label-success {
  background-color: #6ed3cf;
}

.label-info {
  background-color: #f5a3e3;
}

.label-warning {
  background-color: #fae596;
}

.label-danger {
  background-color: #c43235;
}

.label-dark {
  background-color: #173e43;
}

.label-light {
  background-color: #9fadba;
}

.bootstrap-label .label {
  margin-bottom: 10px;
  display: inline-block;
}

.accordion .card {
  margin-bottom: 7px;
}
.accordion .card-header {
  cursor: pointer;
  background: #fff;
}

#accordion-one .fa {
  float: right;
}
#accordion-one [data-toggle="collapse"] .fa:before {
  content: "";
}
#accordion-one [data-toggle="collapse"].collapsed .fa:before {
  content: "";
}

#accordion-two .fa {
  float: right;
}
#accordion-two [data-toggle="collapse"] .fa:before {
  content: "";
}
#accordion-two [data-toggle="collapse"].collapsed .fa:before {
  content: "";
}

#accordion-three .fa {
  float: right;
}
#accordion-three [data-toggle="collapse"] .fa:before {
  content: "";
}
#accordion-three [data-toggle="collapse"].collapsed .fa:before {
  content: "";
}

.card {
  margin-bottom: 30px;
  border: 0px;
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.no-card-border .card {
  border: 0 !important;
}

.card-img-btn {
  position: absolute;
  bottom: 15px;
  left: 30px;
}

.card-header {
  border-bottom: 0px;
}

.card-bg-img-1 {
  /* background: url(../../assets/images/big/img6.jpg); */
  background-position: center;
  background-size: cover;
  min-height: 300px;
}

.card-bg-img-2 {
  /* background: url(../../assets/images/big/img6.jpg); */
  background-position: center;
  background-size: cover;
  min-height: 400px;
}

.card-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.user-card1 .btn {
  padding: 5px 20px;
  margin: 15px 0px;
  font-weight: 500;
  border-radius: 3px;
}

.user-card2 .btn {
  padding: 5px 20px;
  margin: 20px 0px 15px;
  font-weight: 500;
  border-radius: 3px;
}

.user-card3 .btn {
  padding: 5px 20px;
  margin: 20px 0px 15px;
  font-weight: 500;
  border-radius: 3px;
}

.user-card4 .btn {
  padding: 5px 20px;
  margin: 10px 5px;
  font-weight: 500;
  border-radius: 3px;
}

.user-card5 a img {
  width: 60px;
  margin-right: 10px;
}

.btn-page .btn {
  margin-bottom: 10px;
  margin-right: 5px;
}

.btn {
  padding: 7px 18px;
}
.btn:active,
.btn:focus,
.btn:hover {
  box-shadow: none !important;
}

.btn-xs {
  font-size: 10px;
  padding: 0.25rem 0.5rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
}

.btn-flat {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 60px;
}

.btn-icon-right {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  display: inline-block;
  margin: -8px 0 -8px 13px;
  padding: 10px 0 10px 13px;
}

.btn-icon-left {
  background: #fff;
  border-radius: 100px;
  display: inline-block;
  margin: -5px 13px -5px -15px;
  padding: 5px 10px 6px;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 75px;
  margin-bottom: 15px;
}

.social-icon .btn {
  padding: 7px 14px;
}

.icon-circle {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.btn-primary {
  background: #9068be;
  border-color: #9068be;
  color: #fff;
}
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background: #7649aa;
  color: #fff;
  border-color: #7649aa;
}

.btn-secondary {
  background: #978ded;
  border-color: #978ded;
  color: #fff;
}
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background: #6f61e6;
  color: #fff;
  border-color: #6f61e6;
}

.btn-success {
  background: #6ed3cf;
  border-color: #6ed3cf;
  color: #fff;
}
.btn-success:active,
.btn-success:focus,
.btn-success:hover {
  background: #47c7c2;
  color: #fff;
  border-color: #47c7c2;
}

.btn-warning {
  background: #fae596;
  border-color: #fae596;
  color: #fff;
}
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover {
  background: #f8d965;
  color: #fff;
  border-color: #f8d965;
}

.btn-danger {
  background: #c43235;
  border-color: #c43235;
  color: #fff;
}
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover {
  background: #9b282a;
  color: #fff;
  border-color: #9b282a;
}

.btn-info {
  background: #f5a3e3;
  border-color: #f5a3e3;
  color: #fff;
}
.btn-info:active,
.btn-info:focus,
.btn-info:hover {
  background: #f075d5;
  color: #fff;
  border-color: #f075d5;
}

.btn-light {
  background: #9fadba;
  border-color: #9fadba;
  color: #000;
}
.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  background: #dae0e5;
  color: #fff;
  border-color: #dae0e5;
}

.btn-clean {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: inherit;
}

.btn-clean:hover {
  color: inherit;
}

.btn-dark {
  background: #173e43;
  border-color: #173e43;
  color: #fff;
}
.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
  background: #0a1b1d;
  color: #fff;
  border-color: #0a1b1d;
}

.btn-outline-primary {
  background: transparent;
  border-color: #9068be;
  color: #9068be;
}
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background: #7649aa;
  color: #fff;
  border-color: #7649aa;
}

.btn-outline-secondary {
  background: transparent;
  border-color: #978ded;
  color: #978ded;
}
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  background: #6f61e6;
  color: #fff;
  border-color: #6f61e6;
}

.btn-outline-success {
  background: transparent;
  border-color: #6ed3cf;
  color: #6ed3cf;
}
.btn-outline-success:active,
.btn-outline-success:focus,
.btn-outline-success:hover {
  background: #47c7c2;
  color: #fff;
  border-color: #47c7c2;
}

.btn-outline-warning {
  background: transparent;
  border-color: #fae596;
  color: #fae596;
}
.btn-outline-warning:active,
.btn-outline-warning:focus,
.btn-outline-warning:hover {
  background: #f8d965;
  color: #fff;
  border-color: #f8d965;
}

.btn-outline-danger {
  background: transparent;
  border-color: #c43235;
  color: #c43235;
}
.btn-outline-danger:active,
.btn-outline-danger:focus,
.btn-outline-danger:hover {
  background: #9b282a;
  color: #fff;
  border-color: #9b282a;
}

.btn-outline-info {
  background: transparent;
  border-color: #f5a3e3;
  color: #f5a3e3;
}
.btn-outline-info:active,
.btn-outline-info:focus,
.btn-outline-info:hover {
  background: #f075d5;
  color: #fff;
  border-color: #f075d5;
}

.btn-outline-light {
  background: transparent;
  border-color: #f8f9fa;
  color: #fff;
}
.btn-outline-light:active,
.btn-outline-light:focus,
.btn-outline-light:hover {
  background: #dae0e5;
  color: #fff;
  border-color: #dae0e5;
}

.btn-outline-dark {
  background: transparent;
  border-color: #173e43;
  color: #173e43;
}
.btn-outline-dark:active,
.btn-outline-dark:focus,
.btn-outline-dark:hover {
  background: #0a1b1d;
  color: #fff;
  border-color: #0a1b1d;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}
.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #cd201f;
  border-color: #cd201f;
  color: #fff;
}
.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  background: #a11918;
  color: #fff;
  border-color: #a11918;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}
.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}
.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}
.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}
.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}
.btn-google:active,
.btn-google:focus,
.btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}
.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}
.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}
.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}
.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}
.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}
.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}
.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}
.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}
.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}
.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.progress-bar {
  background-color: #9068be;
}

.progress-bar-primary {
  background-color: #9068be;
}

.progress-bar-success {
  background-color: #6ed3cf;
}

.progress-bar-info {
  background-color: #f5a3e3;
}

.progress-bar-danger {
  background-color: #c43235;
}

.progress-bar-warning {
  background-color: #fae596;
}

.progress-bar-pink {
  background-color: #e91e63;
}

.progress-bar.active,
.progress.active .progress-bar {
  animation: 2s linear 0s normal none infinite running progress-bar-stripes;
}

.progress-vertical {
  display: inline-block;
  height: 250px;
  margin-bottom: 0;
  margin-right: 20px;
  min-height: 250px;
  position: relative;
}

.progress-vertical-bottom {
  display: inline-block;
  height: 250px;
  margin-bottom: 0;
  margin-right: 20px;
  min-height: 250px;
  position: relative;
  transform: rotate(180deg);
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}
@keyframes myanimation {
  0% {
    width: 0;
  }
}
.browser .progress {
  height: 8px;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 1px solid #9068be;
}

.dropdown-menu {
  font-size: inherit;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 10px;
}
.custom-dropdown .dropdown-menu {
  border: 1px solid rgba(120, 130, 140, 0.13);
  min-width: auto;
}
.custom-dropdown .dropdown-menu .dropdown-item {
  padding: 5px 15px;
  color: #678098;
}

.nestable-cart {
  overflow: hidden;
}

.dd-item > button {
  height: 21px;
}

.weather-one i {
  font-size: 80px;
  position: relative;
  top: 5px;
}
.weather-one h2 {
  display: inline-block;
  float: right;
  font-size: 48px;
}
.weather-one .city {
  position: relative;
  text-align: right;
  top: -25px;
}
.weather-one .currently {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  top: 25px;
}
.weather-one .celcious {
  text-align: right;
  font-size: 20px;
}

.counter-content {
  text-align: center;
  padding: 80px 0;
}
.counter-content span {
  display: inline-block;
  padding-top: 5px;
  font-weight: 500;
}

.counter-number {
  font-size: 48px;
  line-height: 62px;
  font-weight: 700;
}

.vmap {
  width: 100%;
  height: 400px;
}

.table td {
  line-height: 36px;
}

.verticle-middle tr td {
  vertical-align: middle;
}

.header-border thead tr th {
  border: 0 solid rgba(120, 130, 140, 0.13);
  font-weight: 700;
}

.table-bordered thead td {
  border-bottom-width: 1px;
}

.table-bordered thead th {
  border-bottom-width: 1px;
}

.table-primary {
  background-color: #d2c1e4;
  color: #000;
}

.table-secondary {
  background-color: #fbfbfe;
  color: #000;
}

.table-success {
  background-color: #d0f1ef;
  color: #000;
}

.table-warning {
  background-color: white;
  color: #000;
}

.table-danger {
  background-color: #e39294;
  color: #000;
}

.table-info {
  background-color: white;
  color: #000;
}

.table-dark {
  background-color: #3896a2;
  color: #000;
}

.table-active {
  background-color: white;
  color: #000;
}

.thead-primary tr th {
  background-color: #9068be;
  color: #fff;
  border-bottom: 0;
}

.thead-secondary tr th {
  background-color: #978ded;
  color: #fff;
  border-bottom: 0;
}

.thead-success tr th {
  background-color: #6ed3cf;
  color: #fff;
  border-bottom: 0;
}

.thead-info tr th {
  background-color: #f5a3e3;
  color: #fff;
  border-bottom: 0;
}

.thead-warning tr th {
  background-color: #fae596;
  color: #fff;
  border-bottom: 0;
}

.thead-danger tr th {
  background-color: #c43235;
  color: #fff;
  border-bottom: 0;
}

.thead-dark tr th {
  background-color: #173e43;
  color: #fff;
  border-bottom: 0;
}

.thead-muted tr th {
  background-color: #abafb3;
  color: #fff;
  border-bottom: 0;
}

.primary-table-bordered {
  border: 2px solid #9068be;
}

.secondary-table-bordered {
  border: 2px solid #978ded;
}

.success-table-bordered {
  border: 2px solid #6ed3cf;
}

.info-table-bordered {
  border: 2px solid #f5a3e3;
}

.danger-table-bordered {
  border: 2px solid #c43235;
}

.warning-table-bordered {
  border: 2px solid #fae596;
}

.dark-table-bordered {
  border: 2px solid #173e43;
}

.muted-table-bordered {
  border: 2px solid #abafb3;
}

.primary-table-bg {
  background: #aa8ccd;
  color: #fff;
}
.primary-table-bg thead tr th {
  background-color: #9068be;
  color: #fff;
  border-bottom: 0;
}
.primary-table-bg tbody tr:first-child td,
.primary-table-bg tbody tr:first-child th {
  border-top: 0;
}
.primary-table-bg tbody tr td,
.primary-table-bg tbody tr th {
  border-top: 1px solid #dfd3ec;
}

.secondary-table-bg {
  background: #bfb9f4;
  color: #fff;
}
.secondary-table-bg thead tr th {
  background-color: #978ded;
  color: #fff;
  border-bottom: 0;
}
.secondary-table-bg tbody tr:first-child td,
.secondary-table-bg tbody tr:first-child th {
  border-top: 0;
}
.secondary-table-bg tbody tr td,
.secondary-table-bg tbody tr th {
  border-top: 1px solid white;
}

.success-table-bg {
  background: #95dfdc;
  color: #fff;
}
.success-table-bg thead tr th {
  background-color: #6ed3cf;
  color: #fff;
  border-bottom: 0;
}
.success-table-bg tbody tr:first-child td,
.success-table-bg tbody tr:first-child th {
  border-top: 0;
}
.success-table-bg tbody tr td,
.success-table-bg tbody tr th {
  border-top: 1px solid #e3f7f6;
}

.info-table-bg {
  background: #fad1f1;
  color: #fff;
}
.info-table-bg thead tr th {
  background-color: #f5a3e3;
  color: #fff;
  border-bottom: 0;
}
.info-table-bg tbody tr:first-child td,
.info-table-bg tbody tr:first-child th {
  border-top: 0;
}
.info-table-bg tbody tr td,
.info-table-bg tbody tr th {
  border-top: 1px solid white;
}

.warning-table-bg {
  background: #fcf1c7;
  color: #fff;
}
.warning-table-bg thead tr th {
  background-color: #fae596;
  color: #fff;
  border-bottom: 0;
}
.warning-table-bg tbody tr:first-child td,
.warning-table-bg tbody tr:first-child th {
  border-top: 0;
}
.warning-table-bg tbody tr td,
.warning-table-bg tbody tr th {
  border-top: 1px solid white;
}

.danger-table-bg {
  background: #d45558;
  color: #fff;
}
.danger-table-bg thead tr th {
  background-color: #c43235;
  color: #fff;
  border-bottom: 0;
}
.danger-table-bg tbody tr:first-child td,
.danger-table-bg tbody tr:first-child th {
  border-top: 0;
}
.danger-table-bg tbody tr td,
.danger-table-bg tbody tr th {
  border-top: 1px solid #e8a7a8;
}

.dark-table-bg {
  background: #246169;
  color: #fff;
}
.dark-table-bg thead tr th {
  background-color: #173e43;
  color: #fff;
  border-bottom: 0;
}
.dark-table-bg tbody tr:first-child td,
.dark-table-bg tbody tr:first-child th {
  border-top: 0;
}
.dark-table-bg tbody tr td,
.dark-table-bg tbody tr th {
  border-top: 1px solid #3ea7b5;
}

.muted-table-bg {
  background: #c6c9cb;
  color: #fff;
}
.muted-table-bg thead tr th {
  background-color: #abafb3;
  color: #fff;
  border-bottom: 0;
}
.muted-table-bg tbody tr:first-child td,
.muted-table-bg tbody tr:first-child th {
  border-top: 0;
}
.muted-table-bg tbody tr td,
.muted-table-bg tbody tr th {
  border-top: 1px solid #fbfcfc;
}

.primary-table-bg-hover thead tr th {
  background-color: #9068be;
  color: #fff;
  border-bottom: 0;
}
.primary-table-bg-hover tbody tr {
  background: #aa8ccd;
  color: #fff;
}
.primary-table-bg-hover tbody tr:hover {
  background: #9d7ac6;
}
.primary-table-bg-hover tbody tr:first-child td,
.primary-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.primary-table-bg-hover tbody tr td,
.primary-table-bg-hover tbody tr th {
  border-top: 1px solid #dfd3ec;
}

.secondary-table-bg-hover thead tr th {
  background-color: #978ded;
  color: #fff;
  border-bottom: 0;
}
.secondary-table-bg-hover tbody tr {
  background: #bfb9f4;
  color: #fff;
}
.secondary-table-bg-hover tbody tr:hover {
  background: #aba3f0;
}
.secondary-table-bg-hover tbody tr:first-child td,
.secondary-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.secondary-table-bg-hover tbody tr td,
.secondary-table-bg-hover tbody tr th {
  border-top: 1px solid white;
}

.success-table-bg-hover thead tr th {
  background-color: #6ed3cf;
  color: #fff;
  border-bottom: 0;
}
.success-table-bg-hover tbody tr {
  background: #95dfdc;
  color: #fff;
}
.success-table-bg-hover tbody tr:hover {
  background: #82d9d5;
}
.success-table-bg-hover tbody tr:first-child td,
.success-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.success-table-bg-hover tbody tr td,
.success-table-bg-hover tbody tr th {
  border-top: 1px solid #e3f7f6;
}

.info-table-bg-hover thead tr th {
  background-color: #f5a3e3;
  color: #fff;
  border-bottom: 0;
}
.info-table-bg-hover tbody tr {
  background: #fad1f1;
  color: #fff;
}
.info-table-bg-hover tbody tr:hover {
  background: #f8baea;
}
.info-table-bg-hover tbody tr:first-child td,
.info-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.info-table-bg-hover tbody tr td,
.info-table-bg-hover tbody tr th {
  border-top: 1px solid white;
}

.warning-table-bg-hover thead tr th {
  background-color: #fae596;
  color: #fff;
  border-bottom: 0;
}
.warning-table-bg-hover tbody tr {
  background: #fcf1c7;
  color: #fff;
}
.warning-table-bg-hover tbody tr:hover {
  background: #fbebae;
}
.warning-table-bg-hover tbody tr:first-child td,
.warning-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.warning-table-bg-hover tbody tr td,
.warning-table-bg-hover tbody tr th {
  border-top: 1px solid white;
}

.danger-table-bg-hover thead tr th {
  background-color: #c43235;
  color: #fff;
  border-bottom: 0;
}
.danger-table-bg-hover tbody tr {
  background: #d45558;
  color: #fff;
}
.danger-table-bg-hover tbody tr:hover {
  background: #cf4144;
}
.danger-table-bg-hover tbody tr:first-child td,
.danger-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.danger-table-bg-hover tbody tr td,
.danger-table-bg-hover tbody tr th {
  border-top: 1px solid #e8a7a8;
}

.dark-table-bg-hover thead tr th {
  background-color: #173e43;
  color: #fff;
  border-bottom: 0;
}
.dark-table-bg-hover tbody tr {
  background: #246169;
  color: #fff;
}
.dark-table-bg-hover tbody tr:hover {
  background: #1e5056;
}
.dark-table-bg-hover tbody tr:first-child td,
.dark-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.dark-table-bg-hover tbody tr td,
.dark-table-bg-hover tbody tr th {
  border-top: 1px solid #3ea7b5;
}

.muted-table-bg-hover thead tr th {
  background-color: #abafb3;
  color: #fff;
  border-bottom: 0;
}
.muted-table-bg-hover tbody tr {
  background: #c6c9cb;
  color: #fff;
}
.muted-table-bg-hover tbody tr:hover {
  background: #b8bcbf;
}
.muted-table-bg-hover tbody tr:first-child td,
.muted-table-bg-hover tbody tr:first-child th {
  border-top: 0;
}
.muted-table-bg-hover tbody tr td,
.muted-table-bg-hover tbody tr th {
  border-top: 1px solid #fbfcfc;
}

.dataTables_wrapper {
  padding-top: 10px;
}

.dt-buttons {
  display: inline-block;
  margin-bottom: 15px;
  padding-top: 5px;
}
.dt-buttons .btn {
  margin-bottom: 10px;
}

.dt-buttons .dt-button {
  border-radius: 4px;
  margin-right: 3px;
  padding: 5px 15px;
}

.dataTables_info,
.dataTables_length {
  display: inline-block;
}

.dataTables_length {
  margin-top: 10px;
}

.dataTables_length select {
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  border: 0 none;
  padding-bottom: 5px;
  transition: background 0s ease-out 0s;
}

.dataTables_length select:focus {
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  outline: medium none;
  transition-duration: 0.3s;
}

.dataTables_filter {
  float: right;
  margin-top: 10px;
}

.dataTables_filter input {
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
  margin-left: 10px;
  transition: background 0s ease-out 0s;
}

.dataTables_filter input:focus {
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  outline: medium none;
  transition-duration: 0.3s;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

table.dataTable thead .sorting_asc::after {
  content: "";
  cursor: pointer;
  font-family: fontawesome;
  margin-left: 10px;
}

table.dataTable thead .sorting_desc::after {
  content: "";
  cursor: pointer;
  font-family: fontawesome;
  margin-left: 10px;
}

table.dataTable thead .sorting::after {
  content: "";
  cursor: pointer;
  font-family: fontawesome !important;
  margin-left: 10px;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.25em;
  text-align: right;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 1px solid rgba(120, 130, 140, 0.13);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
  background: transparent;
  box-shadow: none;
  cursor: default;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: medium none;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dt-bootstrap {
  display: block;
}

.paging_simple_numbers .pagination .paginate_button {
  padding: 0;
}

.paging_simple_numbers .pagination .paginate_button a {
  border: 0 none;
  padding: 2px 10px;
}

#myTable a {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
#myTable i.cc {
  font-size: 30px;
}
#myTable p {
  margin: 0px;
}

/*    


Input Style
------------------------*/
.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  height: 45px;
}
.form-control:hover {
  box-shadow: none;
}
.form-control.active,
.form-control:focus {
  box-shadow: none;
}

.input-default {
  border-radius: 4px;
}

.input-flat {
  border-radius: 0;
}

.input-rounded {
  border-radius: 100px;
}

/*****************************************
  Input Size
******************************************/
.form-control {
  min-height: 40px;
}

.form-control-sm {
  min-height: 36px;
}

.form-control-lg {
  min-height: 52px;
}

/*****************************************
  upload button styles
******************************************/
.file-upload {
  position: relative;
  display: inline-block;
}
.file-upload .file-upload__label {
  display: block;
  padding: 0.5em 1.5em;
  border-radius: 0.4em;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.file-upload .file-upload__label:hover {
  cursor: pointer;
}
.file-upload .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}

.drop-menu {
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  font-size: 16px;
  height: 100%;
  text-align: left;
}
.drop-menu .select {
  cursor: pointer;
  display: block;
  padding: 10px;
}
.drop-menu .select i {
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  float: right;
}
.drop-menu .select .currency {
  font-size: 13px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  float: right;
}
.drop-menu.active {
  border-radius: 5px 5px 0 0;
}
.drop-menu.active:hover {
  border-radius: 5px 5px 0 0;
}
.drop-menu.active .select i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.drop-menu .dropeddown {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 1px;
  border-radius: 0 1px 5px 5px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
  padding: 0;
  list-style: none;
}
.drop-menu .dropeddown li {
  padding: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.wysihtml5-toolbar {
  height: 50px;
}

.textarea_editor {
  min-height: 300px;
}

.bootstrap-touchspin .input-group-btn,
.bootstrap-touchspin .input-group-prepend,
.bootstrap-touchspin .input-group-append,
.bootstrap-touchspin .form-control {
  margin: 10px 0px 0px;
}

.dropzone {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
  background: #f1f4f5 !important;
}

.switch {
  -moz-user-select: none;
}
.switch * {
  -moz-user-select: none;
}
.switch label {
  cursor: pointer;
}
.switch label input[type="checkbox"] {
  height: 0;
  opacity: 0;
  width: 0;
}
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #84c7c1;
}
.switch label input[type="checkbox"]:checked + .lever::after {
  background-color: #26a69a;
  left: 24px;
}
.switch label .lever {
  background-color: #818181;
  border-radius: 15px;
  content: "";
  display: inline-block;
  height: 15px;
  margin: 0 16px;
  position: relative;
  transition: background 0.3s ease 0s;
  vertical-align: middle;
  width: 40px;
}
.switch label .lever::after {
  background-color: #f1f1f1;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  content: "";
  display: inline-block;
  height: 21px;
  left: -5px;
  position: absolute;
  top: -3px;
  transition: left 0.3s ease 0s, background 0.3s ease 0s,
    box-shadow 0.1s ease 0s, -webkit-box-shadow 0.1s ease 0s;
  width: 21px;
}
.switch label input[type="checkbox"][disabled] + .lever::after {
  background-color: #bdbdbd;
}
.switch label input[type="checkbox"][disabled]:checked + .lever::after {
  background-color: #bdbdbd;
}
.switch input[type="checkbox"][disabled] + .lever {
  cursor: default;
}

input.tabbed[type="checkbox"]:checked:focus:not(:disabled) ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(38, 166, 154, 0.1);
}
input.tabbed[type="checkbox"]:focus:not(:disabled) ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
}

input[type="checkbox"]:checked:not(:disabled) ~ .lever:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(38, 166, 154, 0.1);
}
input[type="checkbox"]:not(:disabled) ~ .lever:active::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
}

.tagsinput {
  overflow-y: auto;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-image: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.tagsinput span.tag {
  display: block;
  float: left;
  padding: 3px 8px;
  text-decoration: none;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.tagsinput span.tag a {
  font-weight: bold;
  text-decoration: none;
  font-size: 11px;
}
.tagsinput input {
  width: 80px;
  margin: 0;
  font-size: 13px;
  border: 1px solid transparent;
  padding: 5px;
  background: transparent;
  outline: 0;
  margin-right: 5px;
  margin-bottom: 5px;
}
.tagsinput div {
  display: block;
  float: left;
}

.tags_clear {
  clear: both;
  width: 100%;
  height: 0;
}

.not_valid {
  background: #fbd8db !important;
  color: #90111a !important;
}

.note-icon-caret,
.note-popover {
  display: none;
}

.note-editor.note-frame .panel-heading {
  padding: 6px 10px 10px;
}

.asColorPicker-dropdown {
  max-width: 260px;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 38px;
  position: absolute;
  right: -35px;
  top: 0;
  width: 37px;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 10px;
  text-decoration: none;
  top: 5px;
}

.daterangepicker td.active {
  background-color: #1976d2;
}
.daterangepicker td.active:hover {
  background-color: #1976d2;
}

.datepicker table tr td.today {
  background: #1976d2 none repeat scroll 0 0;
  color: #ffffff;
}
.datepicker table tr td.today:hover {
  background: #1976d2 none repeat scroll 0 0;
  color: #ffffff;
}
.datepicker table tr td.today.disabled {
  background: #1976d2 none repeat scroll 0 0;
  color: #ffffff;
}
.datepicker table tr td.today.disabled:hover {
  background: #1976d2 none repeat scroll 0 0;
  color: #ffffff;
}
.datepicker td {
  padding: 5px 10px;
}
.datepicker th {
  padding: 5px 10px;
}

#duration-value-axis {
  height: 450px;
  width: 100%;
}

#combined-bullet {
  height: 450px;
  width: 100%;
}

#zoomable {
  height: 450px;
  width: 100%;
}

#chartMap {
  height: 450px;
  width: 100%;
}

#professional-candlesticks {
  width: 100%;
  height: 450px;
}

#comparing-stock-indices {
  width: 100%;
  height: 450px;
}

#multiple-panel-data {
  width: 100%;
  height: 450px;
}

#depth-chart {
  width: 100%;
  height: 450px;
}

.amcharts-export-menu {
  display: none;
}

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 270px;
  margin: 0 auto;
}
.amcharts-data-set-selector-div select {
  border: 0;
  margin-left: 10px;
}

.amChartsInputField {
  border: 0;
}

.amcharts-data-set-select {
  border: 0;
}

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 10px;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
.amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
.c3-legend-item {
  fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line {
  stroke: #fff;
}

.c3 .c3-axis-y path,
.c3 .c3-axis-y line {
  stroke: #fff;
}

.tick text {
  stroke: #fff;
}

.morris-hover {
  position: absolute;
  z-index: 1;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.morris-hover.morris-default-style {
  border-radius: 2px;
  padding: 10px 12px;
  border: none;
}

#morris-area-chart1,
#morris-area-chart2,
#morris-area-chart3,
#morris-area-chart4,
#morris-area-chart5,
#morris-area-chart6 {
  height: 200px;
}

.flot-chart {
  height: 350px;
}

.tooltipflot {
  background-color: #000;
  font-size: 14px;
  padding: 5px 10px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

.legendColorBox > div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 11px;
  padding-left: 5px;
}

#loading,
.error {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}

.error {
  color: red;
}

#loading {
  display: none;
}

#ChartContainer {
  height: 600px;
}

.top {
  margin-top: 8px;
}

#relation-selection {
  margin-left: 18px;
}

.top2 {
  margin-top: 8px;
}

.buttons {
  display: inline-block;
}

.buttons > a,
.help-button {
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 7px;
  margin-right: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all ease-out 0.2s;
  text-decoration: none;
  min-width: 25px;
}

.buttons > a:hover {
  border-color: #d6d6d6;
}

.buttons > a.active {
  color: #dddf0d;
  border-color: #dddf0d;
}

#average-button.active {
  color: #5494d3;
  border-color: #5494d3;
}

.help-button {
  margin-left: 35px;
}

#average-button {
  margin-left: 10px;
}

#about {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: none;
  background: rgba(22, 22, 22, 0.75);
}

#about-inner {
  margin-top: 200px;
  display: inline-block;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 25px;
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: left;
  line-height: 130%;
  box-shadow: 0px 0px 20px #0c0c0c;
}

#about-inner > h1 {
  text-align: center;
  font-size: 1.7em;
  margin: 15px;
  margin-bottom: 25px;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 300px;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 12px;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #9068be;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #c43235;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #6ed3cf;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #fae596;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #313a46;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #f5a3e3;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #9068be;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #c43235;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #6ed3cf;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #fae596;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.superpose {
  color: #eee;
  height: 350px;
  width: 100%;
}

.superclock {
  position: relative;
  width: 300px;
  margin: auto;
}

.superclock1 {
  position: absolute;
  left: 10px;
  top: 10px;
}

.superclock2 {
  position: absolute;
  left: 60px;
  top: 60px;
}

.superclock3 {
  position: absolute;
  left: 110px;
  top: 110px;
}

#login-page2 body {
  /* background: url(../../assets/images/background/login-register2.jpg); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  position: relative;
}
#login-page2 body::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(34, 144, 255, 0.5);
  z-index: -1;
}

#login-page1 body {
  /* background: url(../../assets/images/background/login-register.jpg); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  position: relative;
}
#login-page1 body::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(34, 144, 255, 0.5);
  z-index: -1;
}

.form-input-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.form-input-content .card {
  margin-bottom: 0px;
}
.form-input-content .card .card-body {
  padding: 50px;
}
.form-input-content .card .card-body .form-control {
  background: transparent;
}
.form-input-content .card .card-body .btn {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}

.login-info {
  position: absolute;
  left: 50px;
  bottom: 100px;
}
.login-info h2,
.login-info p,
.login-info h5 {
  color: #fff !important;
}

#error body {
  background: #fff;
}

.error-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error-text {
  font-size: 100px;
  line-height: 80px;
  color: #9068be;
}

#invoice {
  position: relative;
  margin-bottom: 120px;
  padding: 30px;
}

#invoice-table {
  padding: 30px 0;
}

#invoice-top {
  min-height: 120px;
}

#invoice-mid {
  min-height: 120px;
}

#invoice-bot {
  min-height: 250px;
}

.invoice-logo {
  float: left;
  height: 60px;
  width: 60px;
  background: url("http://michaeltruong.ca/images/logo1.png") no-repeat;
  background-size: 60px 60px;
}

.clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url("http://michaeltruong.ca/images/client.jpg") no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}

.invoice-info {
  display: block;
  float: left;
  margin-left: 20px;
}
.invoice-info h2 {
  font-size: 14px;
}
.invoice-info p {
  font-size: 12px;
}

.title {
  float: right;
}
.title h4 {
  text-align: right;
}

.title p {
  text-align: right;
  font-size: 12px;
}

#project {
  margin-left: 52%;
}
#project p {
  font-size: 12px;
}

#invoice-table h2 {
  font-size: 18px;
}

.tabletitle {
  padding: 5px;
}

.table-item {
  width: 50%;
}

.itemtext {
  font-size: 0.9em;
}

#legalcopy {
  margin-top: 30px;
}
#legalcopy p {
  font-size: 12px;
}

.legal {
  width: 70%;
}

/* All Invoice Page Responsive
--------------------------- */
@media (max-width: 480px) {
  .control-bar {
    padding: 15px 15px 40px;
  }
}
@media (max-width: 360px) {
  .notaxrelated {
    margin-top: 15px;
  }
}
.pricing_table_one .pricing_table_content {
  background: #fff;
  text-align: center;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  padding: 25px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.pricing_table_one .pricing_table_content .package {
  font-weight: 700;
  font-size: 18px;
}
.pricing_table_one .pricing_table_content .price {
  font-weight: 700;
  font-size: 50px;
  line-height: 100px;
}
.pricing_table_one .pricing_table_content hr {
  margin: 0;
}
.pricing_table_one .pricing_table_content .price_list {
  padding: 30px 0;
  text-align: left;
  max-width: 175px;
  margin: 0 auto;
}
.pricing_table_one .pricing_table_content .price_list li {
  color: #909093;
  font-size: 14px;
  line-height: 25px;
  padding: 7px 0;
}
.pricing_table_one .pricing_table_content .price_list li i {
  margin-right: 15px;
}
.pricing_table_one .pricing_table_content .price-btn {
  padding: 15px 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 25px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.pricing_table_one .pricing_table_content:focus,
.pricing_table_one .pricing_table_content:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
}
.pricing_table_one .pricing_table_content:focus .price-btn,
.pricing_table_one .pricing_table_content:hover .price-btn {
  color: #c43235;
}

.panel-footer {
  padding: 10px 20px;
}

body.v-dark {
  background: #070713;
  color: #abafb3;
}
body.v-dark a.link {
  color: #ddd;
}
body.v-dark a.link:focus,
body.v-dark a.link:hover {
  color: #f5a3e3;
}
body.v-dark h1,
body.v-dark h2,
body.v-dark h3,
body.v-dark h4,
body.v-dark h5,
body.v-dark h6 {
  color: #ddd;
}
body.v-dark #preloader {
  background: #070713;
}
body.v-dark .loader__bar {
  background: #9068be;
  box-shadow: 1px 1px 0 rgba(120, 130, 140, 0.13);
}
body.v-dark .loader__ball {
  background: #9068be;
}
body.v-dark .icons > a i {
  color: #ddd;
}
body.v-dark .drop-down {
  background: #151538;
  color: #abafb3;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .dropdown-content-heading span {
  color: #abafb3;
}
body.v-dark .dropdown-content-heading i {
  color: #9068be;
}
body.v-dark .dropdown-content-body li {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .dropdown-content-body li.active,
body.v-dark .dropdown-content-body li:focus,
body.v-dark .dropdown-content-body li:hover {
  background: #0e0e26;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .dropdown-content-body li a {
  color: #abafb3;
}
body.v-dark .dropdown-content-body li a.active,
body.v-dark .dropdown-content-body li a:focus,
body.v-dark .dropdown-content-body li a:hover {
  color: #abafb3;
}
body.v-dark .notification-heading {
  color: #ddd;
}
body.v-dark .notification-text {
  color: #abafb3;
}
body.v-dark .notification-unread {
  background: #121231;
}
body.v-dark .notification-unread .notification-heading {
  color: #ddd;
}
body.v-dark .more-link {
  color: #9068be;
}
body.v-dark .dropdown-profile .trial-day {
  color: #9068be;
}
body.v-dark .dropdown-profile li a {
  color: #abafb3;
}
body.v-dark .dropdown-profile li a.active,
body.v-dark .dropdown-profile li a:focus,
body.v-dark .dropdown-profile li a:hover {
  color: #abafb3;
}
body.v-dark svg.pulse-svg .first-circle,
body.v-dark svg.pulse-svg .second-circle,
body.v-dark svg.pulse-svg .third-circle {
  fill: #9068be;
}
body.v-dark .pulse-css {
  background: #9068be;
}
body.v-dark .pulse-css:after,
body.v-dark .pulse-css:before {
  content: "";
  background-color: #9068be;
}
body.v-dark .header {
  background: #0e0e26;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .nav-header {
  background: #070713;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .footer .copyright {
  background: #0e0e26;
}
body.v-dark .header-left .icons > a {
  color: #173e43;
}
body.v-dark .header-search {
  box-shadow: 0px 0px 5px rgba(120, 130, 140, 0.13);
}
body.v-dark .header-search .input-group .form-control:hover,
body.v-dark .header-search .input-group .form-control:focus,
body.v-dark .header-search .input-group .form-control.active {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .header-search .input-group .input-group-text {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .header-right .icons > a {
  color: #173e43;
}
body.v-dark .nav-user {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .nav-user h5 {
  color: #ddd;
}
body.v-dark .nav-user-option .dropdown-menu {
  background: #11112d;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .nav-user-option .dropdown-menu a {
  color: #abafb3;
}
body.v-dark .nav-user-option .dropdown-menu a:hover,
body.v-dark .nav-user-option .dropdown-menu a:focus,
body.v-dark .nav-user-option .dropdown-menu a.active {
  background: #0e0e26;
  color: #9068be;
}
body.v-dark .nav-label {
  color: #ddd;
}
body.v-dark .nk-sidebar {
  background: #0e0e26;
}
body.v-dark .nk-sidebar ul {
  background: #020204;
}
body.v-dark .nk-sidebar .metismenu {
  background: #0e0e26;
}
body.v-dark .nk-sidebar .metismenu a {
  color: #abafb3;
}
body.v-dark .nk-sidebar .metismenu > li:hover > a,
body.v-dark .nk-sidebar .metismenu > li:focus > a {
  background: #9068be;
  color: #ddd;
}
body.v-dark .nk-sidebar .metismenu > li.active > a {
  background: #9068be;
  color: #ddd;
}
body.v-dark .nk-sidebar .metismenu li ul li:hover a,
body.v-dark .nk-sidebar .metismenu li ul li:focus a {
  color: #9068be;
}
body.v-dark .nk-sidebar .metismenu li ul li.active a {
  color: #9068be;
}
@media (min-width: 768px) {
  body.v-dark .mini-nav .nk-sidebar .metismenu li > ul {
    background: #f2f6f8;
  }
  body.v-dark .mini-nav .nk-sidebar .metismenu > li:hover > a {
    background: #343a40;
  }
  body.v-dark .mini-nav .nk-sidebar .metismenu > li:hover > a .nav-text {
    color: #fff;
  }
}
body.v-dark .hamburger .line {
  background-color: #abafb3;
}
body.v-dark .timeline:before {
  background-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .countdown-content h1 {
  color: #ddd !important;
}
body.v-dark .countdown-content p {
  color: #ddd;
}
body.v-dark .timer .days,
body.v-dark .timer .hours,
body.v-dark .timer .minutes,
body.v-dark .timer .seconds {
  color: #ddd;
}
body.v-dark .countdown-right-content h6 {
  color: #ddd !important;
}
body.v-dark .countdown-token-card .card h2 {
  color: #fff !important;
}
body.v-dark .countdown-token-card .card h5 {
  color: #fff !important;
}
body.v-dark .timeline-header {
  background: #070713;
  color: #fff;
}
body.v-dark .timeline-header h1 {
  color: #fff !important;
}
body.v-dark .timeline-header p {
  color: #fff !important;
}
body.v-dark #timeline div:after {
  background: #abafb3;
}
body.v-dark #timeline h3 {
  color: #ddd;
}
body.v-dark #timeline section h4 {
  color: #abafb3;
}
body.v-dark #timeline section ul:first-of-type:after {
  background: #070713;
  border: 2px solid #fff;
}
body.v-dark #timeline section ul li span.price {
  color: mediumturquoise;
}
body.v-dark .input {
  background-color: #9068be;
}
body.v-dark .input::before,
body.v-dark .input::after {
  background-color: #9068be;
}
body.v-dark .input.active {
  background-color: #9068be;
}
body.v-dark .input.active::before {
  background-color: #9068be;
}
body.v-dark .input.active::after {
  background-color: #173e43;
}
body.v-dark .input.active ~ .input {
  background-color: #173e43;
}
body.v-dark .input.active ~ .input::before,
body.v-dark .input.active ~ .input::after {
  background-color: #173e43;
}
@media (max-width: 600px) {
  body.v-dark .input {
    background-color: #9068be;
  }
  body.v-dark .input span::after {
    color: #173e43;
  }
}
body.v-dark .user-job-title {
  color: #9068be;
}
body.v-dark .contact-title {
  color: #abafb3;
}
body.v-dark .phone-number,
body.v-dark .mail-address,
body.v-dark .contact-email,
body.v-dark .contact-website,
body.v-dark .contact-skype,
body.v-dark .birth-date,
body.v-dark .gender {
  color: #ddd;
}
body.v-dark .add-wallet {
  border: 1px dashed rgba(120, 130, 140, 0.13);
}
body.v-dark .add-wallet a p {
  color: #ddd;
}
body.v-dark .wallet-card .wallet-address .btn {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #070713;
}
body.v-dark .wallet-card .wallet-address .btn:hover,
body.v-dark .wallet-card .wallet-address .btn:focus,
body.v-dark .wallet-card .wallet-address .btn:active,
body.v-dark .wallet-card .wallet-address .btn.active {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #070713;
}
body.v-dark .wallet-card .wallet-address .input-group-append i {
  color: #ddd;
}
body.v-dark .chartTypeTabLinks {
  background: #173e43 !important;
}
body.v-dark .cTypeTabActive {
  background: #9068be !important;
}
body.v-dark .tabContainer .tablinks {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
  color: #9068be;
}
body.v-dark .ccc-widget.ccc-chart-v3 > div {
  background: #070713 !important;
  border: 0px !important;
}
body.v-dark .email-list .message a {
  color: #abafb3;
}
body.v-dark .email-list .message:hover {
  background: rgba(152, 166, 173, 0.15);
}
body.v-dark .email-checkbox {
  box-shadow: inset 0 0 0 1px #abafb3;
}
body.v-dark .mail-list a {
  color: #fff;
}
body.v-dark .toolbar .btn-group .btn {
  background: #173e43;
  border: 0px;
  color: #fff;
}
body.v-dark .toolbar .btn-group .btn i {
  color: #fff !important;
}
body.v-dark .toolbar .btn-group .dropdown-menu {
  background: #070713;
}
body.v-dark .toolbar .btn-group .dropdown-menu a {
  color: #abafb3;
}
body.v-dark .read-content textarea {
  background: #070713;
  color: #abafb3;
}
body.v-dark .compose-content .wysihtml5-toolbar {
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .compose-content .textarea_editor {
  background: #070713 !important;
}
body.v-dark .compose-content .dropzone {
  background: #070713 !important;
}
body.v-dark .profile-info {
  background: #070713;
}
body.v-dark .profile-info h4 {
  color: #ddd !important;
}
body.v-dark .profile-info h4.text-primary {
  color: #9068be !important;
}
body.v-dark .profile-info p {
  color: #abafb3 !important;
}
body.v-dark .profile-tab .nav-item .nav-link {
  color: #abafb3;
}
body.v-dark .profile-tab .nav-item .nav-link:hover,
body.v-dark .profile-tab .nav-item .nav-link.active {
  background: transparent;
  border-bottom: 2px solid #9068be;
  color: #ddd;
}
body.v-dark .post-input a i {
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #abafb3;
}
body.v-dark .profile-uoloaded-post a h4 {
  color: #ddd !important;
}
body.v-dark .fc-day {
  background: #10112d;
}
body.v-dark .fc-widget-header {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .fc-content {
  color: #ddd;
}
body.v-dark .fc-widget-content {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .fc th.fc-widget-header {
  background: rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .fc-button {
  background: #fff;
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
  color: #abafb3;
}
body.v-dark .fc-state-hover {
  background: #fff !important;
}
body.v-dark .fc-state-highlight {
  background: #10112d !important;
}
body.v-dark .fc-cell-overlay {
  background: #fff !important;
}
body.v-dark .fc-unthemed .fc-today {
  background: #10112d !important;
}
body.v-dark .external-event {
  color: #fff;
}
body.v-dark #add-category .modal-header,
body.v-dark #event-modal .modal-header {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark #add-category .modal-content,
body.v-dark #event-modal .modal-content {
  background: #0b0c21;
}
body.v-dark .pignose-calendar-top-month {
  color: #ddd !important;
}
body.v-dark .pignose-calendar-top-icon {
  color: #ddd !important;
}
body.v-dark .page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item {
  color: #ddd;
}
body.v-dark
  .page-titles
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item:before {
  color: #abafb3;
}
body.v-dark .page-titles .breadcrumb .breadcrumb-item.active {
  color: #9068be;
}
body.v-dark .label {
  color: #fff;
}
body.v-dark .accordion .card-header {
  background: #173e43;
  color: #ddd;
}
body.v-dark .card {
  background: #0e0e26;
}
body.v-dark .text-white .card-title {
  color: #fff;
}
body.v-dark .card-title {
  color: #ddd;
}
body.v-dark .card-footer {
  background: #11112d !important;
}
body.v-dark .btn-icon-left {
  background: #fff;
}
body.v-dark .default-tab .nav-link {
  color: #ddd;
}
body.v-dark .default-tab .nav-link.active {
  color: #9068be;
}
body.v-dark .custom-tab-1 .nav-link {
  color: #ddd;
}
body.v-dark .custom-tab-1 .nav-link.active {
  background: #dfd3ec;
  border-color: #dfd3ec;
  color: #9068be;
}
body.v-dark .custom-dropdown .dropdown-menu {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .custom-dropdown .dropdown-menu .dropdown-item {
  color: #abafb3;
}
body.v-dark .table > tbody > tr > td,
body.v-dark .table > tbody > tr > th,
body.v-dark .table > tfoot > tr > td,
body.v-dark .table > tfoot > tr > th,
body.v-dark .table > thead > tr > td,
body.v-dark .table > thead > tr > th {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .table > thead > tr > th {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .table-bordered td,
body.v-dark .table-bordered th {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: black;
}
body.v-dark .table-hover tbody tr:hover {
  background-color: black;
}
body.v-dark .header-border thead tr {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .dt-buttons .dt-button {
  background: #1976d2;
  color: #fff;
}
body.v-dark .dt-buttons .dt-button:hover {
  background: #2f3d4a;
}
body.v-dark .dataTables_info select,
body.v-dark .dataTables_length select {
  color: #ddd;
}
body.v-dark .dataTables_info option,
body.v-dark .dataTables_length option {
  color: #ddd;
}
body.v-dark .dataTables_length select {
  background-image: linear-gradient(#070713, #070713),
    linear-gradient(#173e43, #173e43);
}
body.v-dark .dataTables_length select:focus {
  background-image: linear-gradient(#070713, #070713),
    linear-gradient(#173e43, #173e43);
}
body.v-dark .dataTables_filter input {
  background-color: transparent;
  background-image: linear-gradient(#070713, #070713),
    linear-gradient(#173e43, #173e43);
}
body.v-dark .dataTables_filter input:focus {
  background-image: linear-gradient(#070713, #070713),
    linear-gradient(#173e43, #173e43);
}
body.v-dark table.dataTable thead .sorting::after {
  color: rgba(50, 50, 50, 0.5);
}
body.v-dark .dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #67757c;
}
body.v-dark .dataTables_wrapper .dataTables_paginate .paginate_button.current,
body.v-dark
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover {
  background-color: #1976d2;
  border: 1px solid #1976d2;
  color: #ffffff !important;
}
body.v-dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
body.v-dark
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:active,
body.v-dark
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover {
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #67757c;
}
body.v-dark .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #1976d2;
  border: 1px solid #1976d2;
  color: white;
}
body.v-dark .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background-color: #67757c;
}
body.v-dark .tablesaw-bar .btn-group label {
  color: #67757c !important;
}
body.v-dark .paging_simple_numbers .pagination .paginate_button {
  background: #fff;
}
body.v-dark .paging_simple_numbers .pagination .paginate_button a {
  background: #070713 !important;
  padding: 5px 10px;
  color: #abafb3;
}
body.v-dark .paging_simple_numbers .pagination .paginate_button:hover {
  background: #fff;
}
body.v-dark .paging_simple_numbers .pagination .paginate_button.active a,
body.v-dark .paging_simple_numbers .pagination .paginate_button:hover a {
  background: #1976d2;
  color: #ddd;
}
body.v-dark .jsgrid-grid-header {
  border: 0px !important;
}
body.v-dark th.jsgrid-header-cell {
  border: 0px !important;
}
body.v-dark .jsgrid-header-cell {
  background: #151538;
  border-color: rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .jsgrid-filter-row .jsgrid-cell {
  background: #0e0e26;
  border-color: rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .jsgrid-filter-row .jsgrid-cell input,
body.v-dark .jsgrid-filter-row .jsgrid-cell select {
  background: transparent;
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .jsgrid-row .jsgrid-cell {
  background: transparent;
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .jsgrid-alt-row .jsgrid-cell {
  background: #151538;
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .jsgrid-edit-row > .jsgrid-cell,
body.v-dark .jsgrid-filter-row > .jsgrid-cell,
body.v-dark .jsgrid-grid-body,
body.v-dark .jsgrid-grid-header,
body.v-dark .jsgrid-header-row > .jsgrid-header-cell,
body.v-dark .jsgrid-insert-row > .jsgrid-cell {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .form-control {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #070713;
  color: #fff;
}
body.v-dark .form-control:hover {
  background: #070713;
  color: #fff;
}
body.v-dark .form-control.active,
body.v-dark .form-control:focus {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #070713;
  color: #fff;
}
body.v-dark .input-group-text {
  background: #173e43;
  color: #fff;
  border-color: #173e43;
}
body.v-dark .custom-select {
  background-color: transparent;
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .drop-menu {
  background: #070713;
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #abafb3;
}
body.v-dark .drop-menu .select span {
  color: #fff;
}
body.v-dark .drop-menu .select i {
  color: #fff;
}
body.v-dark .drop-menu .select .currency {
  color: #bababa;
}
body.v-dark .drop-menu:active {
  background-color: #151538;
}
body.v-dark .drop-menu.active {
  background-color: #151538;
}
body.v-dark .drop-menu.active:hover {
  background-color: #151538;
}
body.v-dark .drop-menu .dropeddown {
  background-color: #151538;
}
body.v-dark .drop-menu .dropeddown li {
  color: #fff;
}
body.v-dark .drop-menu .dropeddown li:hover {
  background-color: #22225e;
}
body.v-dark .drop-menu .dropeddown li:active {
  background-color: #22225e;
}
body.v-dark .custom-file-label {
  background-color: transparent;
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-dark .file-upload .file-upload__label {
  color: #fff;
  background-color: #151538;
}
body.v-dark .file-upload .file-upload__label:hover {
  background-color: #22225e;
}
body.v-dark .dropzone {
  background: #10112d;
  border: 2px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .tagsinput {
  color: #fff;
  background-color: #070713;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .tagsinput span.tag {
  border: 1px solid #6ed3cf;
  background: #6ed3cf;
  color: #fff;
}
body.v-dark .tagsinput span.tag a {
  color: #fff;
}
body.v-dark .tagsinput span.tag.tag--yellow {
  background-color: #fae596;
  border-color: #fae596;
}
body.v-dark .tagsinput input {
  color: #fff !important;
}
body.v-dark .note-editor.note-frame {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .note-editor.note-frame .panel-heading {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .note-editor .note-editable {
  background-color: #070713 !important;
}
body.v-dark .note-editor .note-btn {
  background: #173e43;
  color: #fff;
}
body.v-dark .note-editor.note-frame .note-statusbar {
  background-color: #151538;
}
body.v-dark .select2-selection {
  background: #070713 !important;
  border-color: rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .select2-selection .select2-selection__rendered {
  color: #fff !important;
}
body.v-dark
  .select2-selection
  .select2-selection__rendered
  .select2-selection__choice {
  background: #22225e;
  border-color: #22225e;
}
body.v-dark .select2-dropdown {
  background: #151538;
  border-color: rgba(120, 130, 140, 0.13) !important;
}
body.v-dark .select2-search__field {
  background: #070713;
  border-color: rgba(120, 130, 140, 0.13) !important;
}
body.v-dark
  .select2-container--default
  .select2-results__option[aria-selected="false"] {
  background-color: #151538;
}
body.v-dark
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: #22225e;
}
body.v-dark .amcharts-data-set-selector-div select {
  background: #070713;
  color: #ddd;
}
body.v-dark .amChartsInputField {
  background: #070713;
  color: #ddd;
}
body.v-dark .amcharts-data-set-select {
  background: #070713;
  color: #ddd;
}
body.v-dark .amcharts-period-input,
body.v-dark .amcharts-period-input-selected {
  background: #9068be;
  color: #fff;
}
body.v-dark .morris-hover.morris-default-style {
  color: #666;
  background: rgba(0, 0, 0, 0.7);
  color: #fff !important;
}
body.v-dark .morris-hover-point {
  color: rgba(255, 255, 255, 0.8) !important;
}
body.v-dark .flotTip {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}
body.v-dark .login-logo span {
  color: #fff;
}
body.v-dark .login-form {
  background: #0b0c21;
}
body.v-dark .login-form h4 {
  color: #ddd;
}
body.v-dark .login-form .checkbox {
  color: #ddd;
}
body.v-dark .login-form label {
  color: #ddd;
}
body.v-dark .login-form label a {
  color: #9068be;
}
body.v-dark .social-login-content {
  background: #fcfcfc;
}
body.v-dark .register-link a {
  color: #9068be;
}
body.v-dark #invoice {
  background: #070713;
}
body.v-dark #invoice-table {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .invoice-info h2 {
  color: #ddd;
}
body.v-dark .title h4 {
  color: #ddd;
}
body.v-dark .tabletitle {
  background: rgba(120, 130, 140, 0.13);
}
body.v-dark .service {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-dark .panel-footer {
  background: #131335;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  color: #abafb3;
}

body.v-light {
  background: #f1f4f5;
  color: #678098;
}
body.v-light a.link {
  color: #454545;
}
body.v-light a.link:focus,
body.v-light a.link:hover {
  color: #f5a3e3;
}
body.v-light h1,
body.v-light h2,
body.v-light h3,
body.v-light h4,
body.v-light h5,
body.v-light h6 {
  color: #454545;
}
body.v-light #preloader {
  background: #fff;
}
body.v-light .loader__bar {
  background: #9068be;
  box-shadow: 1px 1px 0 rgba(120, 130, 140, 0.13);
}
body.v-light .loader__ball {
  background: #9068be;
}
body.v-light .icons > a i {
  color: #454545;
}
body.v-light .drop-down {
  background: #fff;
  color: #000;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .dropdown-content-heading {
  background: #eef9ff;
}
body.v-light .dropdown-content-heading span {
  color: #9068be;
}
body.v-light .dropdown-content-heading i {
  color: #9068be;
}
body.v-light .dropdown-content-body li {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .dropdown-content-body li.active,
body.v-light .dropdown-content-body li:focus,
body.v-light .dropdown-content-body li:hover {
  background: white;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .dropdown-content-body li a {
  color: #678098;
}
body.v-light .dropdown-content-body li a.active,
body.v-light .dropdown-content-body li a:focus,
body.v-light .dropdown-content-body li a:hover {
  color: #678098;
}
body.v-light .notification-heading {
  color: #678098;
}
body.v-light .notification-unread {
  background: white;
}
body.v-light .notification-unread .notification-heading {
  color: #454545;
}
body.v-light .more-link {
  color: #9068be;
}
body.v-light .dropdown-profile .trial-day {
  color: #9068be;
}
body.v-light .dropdown-profile li a {
  color: #678098;
}
body.v-light .dropdown-profile li a.active,
body.v-light .dropdown-profile li a:focus,
body.v-light .dropdown-profile li a:hover {
  color: #678098;
}
body.v-light svg.pulse-svg .first-circle,
body.v-light svg.pulse-svg .second-circle,
body.v-light svg.pulse-svg .third-circle {
  fill: #c43235;
}
body.v-light .pulse-css {
  background: #c43235;
}
body.v-light .pulse-css:after,
body.v-light .pulse-css:before {
  content: "";
  background-color: #c43235;
}
body.v-light .header {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
}
body.v-light .nav-header {
  background: #fff;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .footer .copyright {
  background: white;
}
body.v-light .header-left .icons > a {
  color: #173e43;
}
body.v-light .header-search {
  box-shadow: 0px 0px 5px rgba(120, 130, 140, 0.13);
}
body.v-light .header-search .input-group .form-control:hover,
body.v-light .header-search .input-group .form-control:focus,
body.v-light .header-search .input-group .form-control.active {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .header-search .input-group .input-group-text {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .header-right .icons > a {
  color: #173e43;
}
body.v-light .nav-user {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .nav-user h5 {
  color: #454545;
}
body.v-light .nav-user-option .dropdown-menu {
  background: #fff;
  border: 1px solid rgba(120, 130, 140, 0.13);
  box-shadow: 0px 0px 25px rgba(120, 130, 140, 0.13);
}
body.v-light .nav-user-option .dropdown-menu a {
  color: #678098;
}
body.v-light .nav-user-option .dropdown-menu a:hover,
body.v-light .nav-user-option .dropdown-menu a:focus,
body.v-light .nav-user-option .dropdown-menu a.active {
  background: white;
}
body.v-light .nav-label {
  color: #454545;
}
body.v-light .nk-sidebar {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
}
body.v-light .nk-sidebar ul {
  background: #fafafa;
}
body.v-light .nk-sidebar .metismenu {
  background: #fff;
}
body.v-light .nk-sidebar .metismenu a {
  color: #678098;
}
body.v-light .nk-sidebar .metismenu > li:hover > a,
body.v-light .nk-sidebar .metismenu > li:focus > a {
  background: #f9f7fb;
  color: #9068be;
}
body.v-light .nk-sidebar .metismenu > li.active > a {
  background: #f9f7fb;
  color: #9068be;
}
body.v-light .nk-sidebar .metismenu li ul li:hover a,
body.v-light .nk-sidebar .metismenu li ul li:focus a {
  color: #9068be;
}
body.v-light .nk-sidebar .metismenu li ul li.active a {
  color: #9068be;
}
@media (min-width: 768px) {
  body.v-light .mini-nav .nk-sidebar .metismenu li > ul {
    background: #f2f6f8;
  }
  body.v-light .mini-nav .nk-sidebar .metismenu > li:hover > a {
    background: #343a40;
  }
  body.v-light .mini-nav .nk-sidebar .metismenu > li:hover > a .nav-text {
    color: #fff;
  }
}
body.v-light .hamburger .line {
  background-color: #678098;
}
body.v-light .weather-one h2 {
  color: #fff;
}
body.v-light .email-list .message a {
  color: #678098;
}
body.v-light .email-list .message:hover {
  background: rgba(152, 166, 173, 0.15);
}
body.v-light .email-checkbox {
  box-shadow: inset 0 0 0 1px #678098;
}
body.v-light .mail-list a {
  color: #454545;
}
body.v-light .toolbar .btn-group .btn {
  background: #173e43;
  border: 0px;
  color: #fff;
}
body.v-light .toolbar .btn-group .btn i {
  color: #fff !important;
}
body.v-light .toolbar .btn-group .dropdown-menu {
  background: #f1f4f5;
}
body.v-light .toolbar .btn-group .dropdown-menu a {
  color: #678098;
}
body.v-light .read-content textarea {
  background: #f1f4f5;
  color: #678098;
}
body.v-light .compose-content .wysihtml5-toolbar {
  border-color: rgba(120, 130, 140, 0.13);
}
body.v-light .compose-content .textarea_editor {
  background: #f1f4f5 !important;
}
body.v-light .compose-content .dropzone {
  background: #f1f4f5 !important;
}
body.v-light .profile-info {
  background: #f1f4f5;
}
body.v-light .profile-info h4 {
  color: #454545 !important;
}
body.v-light .profile-info h4.text-primary {
  color: #9068be !important;
}
body.v-light .profile-info p {
  color: #678098 !important;
}
body.v-light .profile-tab .nav-item .nav-link {
  color: #678098;
}
body.v-light .profile-tab .nav-item .nav-link:hover,
body.v-light .profile-tab .nav-item .nav-link.active {
  background: transparent;
  border-bottom: 2px solid #9068be;
  color: #454545;
}
body.v-light .post-input a i {
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #678098;
}
body.v-light .profile-uoloaded-post a h4 {
  color: #454545 !important;
}
body.v-light .fc-day {
  background: #f1f4f5;
}
body.v-light .fc-widget-header {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}
body.v-light .fc-content {
  color: #fff;
}
body.v-light .fc-widget-content {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}
body.v-light .fc th.fc-widget-header {
  background: rgba(120, 130, 140, 0.13) !important;
}
body.v-light .fc-button {
  background: #fff;
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
  color: #678098;
}
body.v-light .fc-state-hover {
  background: #fff !important;
}
body.v-light .fc-state-highlight {
  background: #f1f4f5 !important;
}
body.v-light .fc-cell-overlay {
  background: #fff !important;
}
body.v-light .fc-unthemed .fc-today {
  background: #f1f4f5 !important;
}
body.v-light .external-event {
  color: #fff;
}
body.v-light #add-category .modal-header,
body.v-light #event-modal .modal-header {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light #add-category .modal-content,
body.v-light #event-modal .modal-content {
  background: #0b0c21;
}
body.v-light .pignose-calendar-top-month {
  color: #454545 !important;
}
body.v-light .pignose-calendar-top-icon {
  color: #454545 !important;
}
body.v-light
  .page-titles
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item:before {
  color: #678098;
}
body.v-light .page-titles .breadcrumb .breadcrumb-item a {
  color: #454545;
}
body.v-light .page-titles .breadcrumb .breadcrumb-item.active {
  color: #9068be;
}
body.v-light .label {
  color: #fff;
}
body.v-light .btn-outline-light {
  background: transparent;
  border-color: #173e43;
  color: #173e43;
}
body.v-light .btn-outline-light:active,
body.v-light .btn-outline-light:focus,
body.v-light .btn-outline-light:hover {
  background: #0a1b1d;
  color: #173e43;
  border-color: #0a1b1d;
}
body.v-light .accordion .card-header {
  color: #454545 !important;
}
body.v-light .card {
  background: #fff;
  border: 1px solid #fff;
}
body.v-light .text-white .card-title {
  color: #fff;
}
body.v-light .card-title {
  color: #454545;
}
body.v-light .card-footer {
  background: white !important;
}
body.v-light .btn-icon-left {
  background: #fff;
}
body.v-light .default-tab .nav-link {
  color: #454545;
}
body.v-light .default-tab .nav-link.active {
  color: #9068be;
}
body.v-light .custom-tab-1 .nav-link {
  color: #454545;
}
body.v-light .custom-tab-1 .nav-link.active {
  background: #dfd3ec;
  border-color: #dfd3ec;
  color: #9068be;
}
body.v-light .table > tbody > tr > td,
body.v-light .table > tbody > tr > th,
body.v-light .table > tfoot > tr > td,
body.v-light .table > tfoot > tr > th,
body.v-light .table > thead > tr > td,
body.v-light .table > thead > tr > th {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .table > thead > tr > th {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  border-top: 0px solid rgba(120, 130, 140, 0.13);
}
body.v-light .table-bordered td,
body.v-light .table-bordered th {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .dt-buttons .dt-button {
  background: #1976d2;
  color: #fff;
}
body.v-light .dt-buttons .dt-button:hover {
  background: #2f3d4a;
}
body.v-light .dataTables_info select,
body.v-light .dataTables_length select {
  color: #173e43;
}
body.v-light .dataTables_info option,
body.v-light .dataTables_length option {
  color: #454545;
}
body.v-light .dataTables_length select {
  background-image: transparent;
}
body.v-light .dataTables_length select:focus {
  background-image: transparent;
}
body.v-light .dataTables_filter input {
  background-color: transparent;
  background-image: transparent;
}
body.v-light .dataTables_filter input:focus {
  background-image: transparent;
}
body.v-light table.dataTable thead .sorting::after {
  color: rgba(50, 50, 50, 0.5);
}
body.v-light .dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #67757c;
}
body.v-light .dataTables_wrapper .dataTables_paginate .paginate_button.current,
body.v-light
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover {
  background-color: #1976d2;
  border: 1px solid #1976d2;
  color: #ffffff !important;
}
body.v-light .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
body.v-light
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:active,
body.v-light
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover {
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #67757c;
}
body.v-light .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #1976d2;
  border: 1px solid #1976d2;
  color: white;
}
body.v-light .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background-color: #67757c;
}
body.v-light .tablesaw-bar .btn-group label {
  color: #67757c !important;
}
body.v-light .paging_simple_numbers .pagination .paginate_button {
  background: #fff;
}
body.v-light .paging_simple_numbers .pagination .paginate_button:hover {
  background: #fff;
}
body.v-light .paging_simple_numbers .pagination .paginate_button.active a,
body.v-light .paging_simple_numbers .pagination .paginate_button:hover a {
  background: #1976d2;
  color: #fff;
}
body.v-light .dd-handle {
  color: #678098;
}
body.v-light .dd-handle:hover {
  color: #678098;
  background: #f1f4f5;
}
body.v-light #nestable2 .dd-handle {
  color: #678098;
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #f1f4f5;
}
body.v-light #nestable2 .dd-handle:hover {
  background: #f1f4f5;
}
body.v-light #nestable2 .dd-item > button:before {
  color: #678098;
}
body.v-light .dd3-content:hover {
  color: #678098;
  background: #f1f4f5;
}
body.v-light .dd3-content {
  color: #678098;
}
body.v-light .form-control,
body.v-light .dropdown {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #f1f4f5;
  color: #000;
}
body.v-light .form-control:hover,
body.v-light .dropdown:hover {
  background: #f1f4f5;
  color: #000;
}
body.v-light .form-control.active,
body.v-light .form-control:focus {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: #f1f4f5;
  color: #000;
}
body.v-light .dropzone {
  background: #f1f4f5;
  border: 2px solid rgba(120, 130, 140, 0.13);
}
body.v-light .amcharts-data-set-selector-div select {
  background: #ddd;
  color: #000;
}
body.v-light .amChartsInputField {
  background: #ddd;
  color: #000;
}
body.v-light .amcharts-data-set-select {
  background: #ddd;
  color: #000;
}
body.v-light .amcharts-period-input,
body.v-light .amcharts-period-input-selected {
  background: #9068be;
  color: #fff;
}
body.v-light .morris-hover.morris-default-style {
  color: #666;
  background: rgba(0, 0, 0, 0.7);
  color: #fff !important;
}
body.v-light .morris-hover-point {
  color: rgba(255, 255, 255, 0.8) !important;
}
body.v-light .flotTip {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}
body.v-light .login-logo span {
  color: #fff;
}
body.v-light .login-form {
  background: #fff;
}
body.v-light .login-form h4 {
  color: #454545;
}
body.v-light .login-form .checkbox {
  color: #454545;
}
body.v-light .login-form label {
  color: #454545;
}
body.v-light .login-form label a {
  color: #9068be;
}
body.v-light .social-login-content {
  background: #fcfcfc;
}
body.v-light .register-link a {
  color: #9068be;
}
body.v-light #invoice {
  background: #fff;
}
body.v-light #invoice-table {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .invoice-info h2 {
  color: #454545;
}
body.v-light .title h4 {
  color: #454545;
}
body.v-light .tabletitle {
  background: rgba(120, 130, 140, 0.13);
}
body.v-light .service {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
body.v-light .panel-footer {
  background: #fff;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  color: #678098;
}
body.v-light .timeline:before {
  background: #f5f5f5;
}

iframe {
  min-height: 300px;
  overflow: auto;
}

/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px)
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px)
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px)
---------------------------------------------------------------------- */
/*  5 - media screen and (max-width: 1024px)
---------------------------------------------------------------------- */
/*  6 - media screen and (max-width: 991px)
---------------------------------------------------------------------- */
/*  7 - media screen and (max-width: 767px)
---------------------------------------------------------------------- */
/*  8 - media screen and (max-width: 680px)
---------------------------------------------------------------------- */
@media (min-width: 480px) and (max-width: 679px) {
  .header-left .drop-down {
    top: 60px;
  }
}
/*  9 - media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media (min-width: 360px) and (max-width: 479px) {
  .icons {
    position: static !important;
  }
  .icons .drop-down {
    width: 100%;
  }
  .icons .dropdown-profile {
    width: 175px;
  }

  .header-left .drop-down {
    top: 60px;
  }
}
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 359px) {
  .icons {
    position: static !important;
  }
  .icons .drop-down {
    width: 100%;
  }
  .icons .dropdown-profile {
    width: 175px;
  }

  .header-left .drop-down {
    top: 60px;
  }
}
/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px)
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px)
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px)
---------------------------------------------------------------------- */
@media (min-width: 1200px) and (max-width: 1279px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .prf-col {
    min-width: auto;
    padding: 0px 15px;
  }
}
/*  5 - media screen and (max-width: 1024px)
---------------------------------------------------------------------- */
@media (min-width: 992px) and (max-width: 1199px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .prf-col {
    min-width: auto;
    padding: 0px 15px;
  }
}
/*  6 - media screen and (max-width: 991px)
---------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 991px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .prf-col {
    min-width: auto;
    padding: 0px 15px;
  }
}
/*  7 - media screen and (max-width: 767px)
---------------------------------------------------------------------- */
@media (min-width: 680px) and (max-width: 767px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .prf-col {
    min-width: auto;
    padding: 0px 15px;
  }
}
/*  8 - media screen and (max-width: 680px)
---------------------------------------------------------------------- */
@media (min-width: 480px) and (max-width: 679px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .border-right-1 {
    border-right: 0px;
  }
}
/*  9 - media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media (min-width: 360px) and (max-width: 479px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .border-right-1 {
    border-right: 0px;
  }

  .profile-tab .nav.nav-tabs {
    display: block;
    border: 0px;
  }
  .profile-tab .nav.nav-tabs .nav-item .nav-link {
    margin-right: 0px;
  }

  .post-input a i {
    margin-right: 10px;
    padding: 7px 10px;
  }
  .post-input .btn {
    margin-top: -10px;
  }
}
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 359px) {
  .photo-content .profile-photo {
    bottom: auto;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-info .border-right-1 {
    border-right: 0px;
  }

  .profile-tab .nav.nav-tabs {
    display: block;
    border: 0px;
  }
  .profile-tab .nav.nav-tabs .nav-item .nav-link {
    margin-right: 0px;
  }

  .post-input a i {
    margin-right: 10px;
    padding: 7px 10px;
  }
  .post-input .btn {
    margin-top: -12px;
  }
}
/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px)
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px)
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px)
---------------------------------------------------------------------- */
/*  5 - media screen and (max-width: 1024px)
---------------------------------------------------------------------- */
/*  6 - media screen and (max-width: 991px)
---------------------------------------------------------------------- */
/*  7 - media screen and (max-width: 767px)
---------------------------------------------------------------------- */
/*  8 - media screen and (max-width: 680px)
---------------------------------------------------------------------- */
/*  9 - media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media (min-width: 360px) and (max-width: 479px) {
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-left {
    margin: 10px 0px;
  }
}
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 359px) {
  .fc-toolbar .fc-right {
    float: left;
    margin: 10px 0px;
  }

  .fc-toolbar .fc-center {
    display: block;
  }
}
/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px)
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px)
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px)
---------------------------------------------------------------------- */
/*  5 - media screen and (max-width: 1024px)
---------------------------------------------------------------------- */
/*  6 - media screen and (max-width: 991px)
---------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 991px) {
  .countdown-content {
    padding: 30px 0px;
  }
  .countdown-content h1 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .countdown-content .timer .days,
  .countdown-content .timer .hours,
  .countdown-content .timer .minutes,
  .countdown-content .timer .seconds {
    margin-bottom: 15px;
    font-size: 30px;
  }
}
/*  7 - media screen and (max-width: 767px)
---------------------------------------------------------------------- */
@media (min-width: 680px) and (max-width: 767px) {
  .countdown-content {
    padding: 50px 0px;
  }
  .countdown-content h1 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .countdown-content .timer .days,
  .countdown-content .timer .hours,
  .countdown-content .timer .minutes,
  .countdown-content .timer .seconds {
    margin-bottom: 15px;
    font-size: 30px;
  }
}
/*  8 - media screen and (max-width: 680px)
---------------------------------------------------------------------- */
@media (min-width: 480px) and (max-width: 679px) {
  .countdown-content {
    padding: 30px 0px;
  }
  .countdown-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .countdown-content .timer .days,
  .countdown-content .timer .hours,
  .countdown-content .timer .minutes,
  .countdown-content .timer .seconds {
    margin-bottom: 15px;
    font-size: 28px;
  }
  .countdown-content .timer .days span,
  .countdown-content .timer .hours span,
  .countdown-content .timer .minutes span,
  .countdown-content .timer .seconds span {
    font-size: 18px;
  }
  .countdown-content p {
    font-size: 16px;
    line-height: 27px;
  }
}
/*  9 - media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media (min-width: 360px) and (max-width: 479px) {
  .countdown-content {
    padding: 30px 0px;
  }
  .countdown-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .countdown-content .timer .days,
  .countdown-content .timer .hours,
  .countdown-content .timer .minutes,
  .countdown-content .timer .seconds {
    margin-bottom: 15px;
    font-size: 24px;
  }
  .countdown-content .timer .days span,
  .countdown-content .timer .hours span,
  .countdown-content .timer .minutes span,
  .countdown-content .timer .seconds span {
    font-size: 16px;
    display: block;
  }
  .countdown-content p {
    font-size: 16px;
    line-height: 27px;
  }
  .countdown-content .btn {
    margin-bottom: 15px;
    margin-left: 0 !important;
  }
}
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 359px) {
  .countdown-content {
    padding: 0px;
  }
  .countdown-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .countdown-content .timer .days,
  .countdown-content .timer .hours,
  .countdown-content .timer .minutes,
  .countdown-content .timer .seconds {
    margin-bottom: 15px;
    font-size: 24px;
  }
  .countdown-content .timer .days span,
  .countdown-content .timer .hours span,
  .countdown-content .timer .minutes span,
  .countdown-content .timer .seconds span {
    font-size: 16px;
    display: block;
  }
  .countdown-content p {
    font-size: 16px;
    line-height: 27px;
  }
  .countdown-content .btn {
    margin-bottom: 15px;
    margin-left: 0 !important;
  }
}
/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px)
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px)
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px)
---------------------------------------------------------------------- */
/*  5 - media screen and (max-width: 1024px)
---------------------------------------------------------------------- */
/*  6 - media screen and (max-width: 991px)
---------------------------------------------------------------------- */
/*  7 - media screen and (max-width: 767px)
---------------------------------------------------------------------- */
/*  8 - media screen and (max-width: 680px)
---------------------------------------------------------------------- */
/*  9 - media screen and (max-width: 480px)
---------------------------------------------------------------------- */
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */

/*# sourceMappingURL=style.css.map */

/* gambi */
.one-line p {
  display: none;
}

.one-line p:first-child {
  display: inline-block !important;
  height: 21px;
  width: 76%;
}

.autocomplete {
  position: relative;
}
.autocomplete .options {
  position: absolute;
  top: 40px;
  left: 0;
  background: white;
  width: 100%;
  padding: 0;
  z-index: 10;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  box-shadow: 0 30px 25px 8px rgba(0, 0, 0, 0.1);
}
.autocomplete .option {
  display: block;
  padding: 0.25rem;
}
.autocomplete .option .option-text {
  padding: 0.25rem 0.5rem;
}
.autocomplete .option:hover {
  background: #1E90FF;
  color: #fff;
}
.autocomplete .option.disabled {
  background-color: lightgrey;
  cursor: not-allowed;
}
.autocomplete .option.disabled:hover {
  background: lightgrey;
  color: var(--bs-body);
}

.stepProgressContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  position: relative;
}

.stepProgressItem {
  color: rgb(190, 190, 190);
  min-width: 30px;
  height: 30px;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(206, 206, 206);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  transition: background-color 0.3s, width 1s;
}

.stepProgressItem.active,
.stepProgressItem:hover {
  border-color: transparent;
  background-color: #9068be;
  color: #FFF;
}

.stepProgressItem.active {
  border-radius: 10px;
  padding: 0 10px;
}

.stepProgressItem:last-child {
  margin-right: 0;
}

.stepProgressItem .stepProgressName {
  display: none;
}

.stepProgressItem.active .stepProgressName {
  display: block;
}

.stepProgressItem .stepProgressNumber {
  display: block;
}

.stepProgressItem.active .stepProgressNumber {
  display: none;
}

.stepProgressLine {
  height: 2px;
  background-color: black; /*rgb(206, 206, 206);*/
  position: absolute;
  left: 0;
  right: 0;
}


.tags-input {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  min-height: 38px;
  align-items: center;
  cursor: text;
}

.tags-input ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
}

.tags-input .tag {
  background-color: #9068be;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
}

.tags-input .tag .tag-close-icon {
  margin-left: 0.5rem;
  text-decoration: none;
  cursor: pointer;
}